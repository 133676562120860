import { Icon } from '@iconify/react';
import { useContext } from 'react';
import {
	buildCostsBuy,
	buildCostsFreeSectorTotal,
	buildCostsSocialTotal,
	managementFreeSectorCosts,
	managementSocialCosts,
	totalBuyCostsSocialAndFreeSectorOnly,
} from '../../../reducers/investingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CalculationTableRowTerm } from '../../lib/calctable/CalculationTableRowTerm';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { Step } from '../Step';
import { SERVICE_STEP, setServiceValue } from '../s6-servicecost/ServiceCostStep';
import ShowConditional from '../../lib/ShowConditional';

export const INVESTMENT_STEP = 'investment';

function setInvestingValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'investing/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

export function CostsStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	const { noHouses } = state.housingReducer;

	return (
		<Step
			name='Kosten'
			id={INVESTMENT_STEP}
			// wider={true}
			description='Bereken de kosten van bouw en exploitatie'
			icon='ant-design:euro-circle-filled'
			showContent={state.stepReducer.step === INVESTMENT_STEP}
			nextStep={SERVICE_STEP}
			thisStep={INVESTMENT_STEP}
			nextStepValidations={[
				{ valid: state.investingReducer.maintenancePerM2BvoPerYear != null && state.investingReducer.maintenancePerM2BvoPerYear >= 0, error: 'Onderhoudskosten moeten ingevuld zijn' },
				{ valid: state.serviceReducer.inflation != null && state.serviceReducer.inflation >= 0, error: 'Indexatie van onderhoudskosten moeten ingevuld' },
				{ valid: state.investingReducer.insuranceCARPercent != null && state.investingReducer.insuranceCARPercent >= 0, error: 'Percentage opstalverzekering moet ingevuld zijn' },
				{ valid: state.investingReducer.mutationRatePercentPerYear != null && state.investingReducer.mutationRatePercentPerYear >= 0, error: 'Gemiddelde woonduur moet ingevuld zijn' },
				{ valid: state.housingReducer.noHouses.social == 0 || (state.investingReducer.mutationCostSocial != null && state.investingReducer.mutationCostSocial >= 0), error: 'Kosten per mutatie sociale sector woning moet ingevuld zijn' },
				{ valid: state.housingReducer.noHouses.freeSector == 0 || (state.investingReducer.mutationCostFreeSector != null && state.investingReducer.mutationCostFreeSector >= 0), error: 'Kosten per mutatie vrije sector woning moet ingevuld zijn' },
			]}
			summaryComponent={
				<div className="col-2">
					<div>
						<div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
							<div>Management / jaar</div>
							<div>
								<FormattedNumber
									value={managementSocialCosts(state) + managementFreeSectorCosts(state)}
									prefix='€ '
									className='bold-value'
									decimalScale={0} />
							</div>
						</div>
						<ShowConditional condition={state.housingReducer.noHouses.social > 0}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Sociale huur</div>
								<div>
									<FormattedNumber
										className='bold-value'
										value={managementSocialCosts(state)}
										prefix='€ '
										decimalScale={0} />
								</div>
							</div>
						</ShowConditional>
						<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Vrije sector</div>
								<div>
									<FormattedNumber
										className='bold-value'
										value={managementFreeSectorCosts(state)}
										prefix='€ '
										decimalScale={0} />
								</div>
							</div>
						</ShowConditional>
					</div>
					<div>
						<div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
							<div>Bouwkosten</div>
							<div>
								<FormattedNumber
									value={totalBuyCostsSocialAndFreeSectorOnly(state)}
									prefix='€ '
									className='bold-value'
									decimalScale={0} />
							</div>
						</div>
						<ShowConditional condition={state.housingReducer.noHouses.social > 0}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Sociale huur</div>
								<div className="bold-value">
									<FormattedNumber value={buildCostsSocialTotal(state)} prefix='€ ' decimalScale={0} />
								</div>
							</div>
						</ShowConditional>
						<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Vrije sector</div>
								<div className='bold-value'>
									<FormattedNumber
										value={buildCostsFreeSectorTotal(state)}
										prefix='€ '
										decimalScale={0}
									/>
								</div>
							</div>
						</ShowConditional>
						<ShowConditional condition={state.housingReducer.noHouses.buy > 0}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Koop</div>
								<div className='bold-value'>
									<FormattedNumber value={buildCostsBuy(state)} prefix='€ ' decimalScale={0} />
								</div>
							</div>
						</ShowConditional>
					</div>
				</div>
			}>

			<div>
				<h3><Icon icon='mdi:crane' className='icon-blue' /> Bouw- en grondkosten</h3>
				<div className="col-3">
					<ShowConditional condition={noHouses.social > 0}>
						<h4>Sociale huur</h4>

						<div style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.investingReducer.buildCostSocialGround}
								unit='grond/m² perceel'
								decimals={2}
								editable={true}
								wide={true}
								prefix='€ '
								onValueChange={setInvestingValue('buildCostSocialGround', dispatch)}
							/>
						</div>

						<div style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.investingReducer.buildCostSocialFloor}
								unit='bouw/m² bvo'
								decimals={2}
								editable={true}
								wide={true}
								prefix='€ '
								onValueChange={setInvestingValue('buildCostSocialFloor', dispatch)}
							/>
						</div>
					</ShowConditional>

					<ShowConditional condition={noHouses.freeSector > 0}>
						<h4>Vrije sector huur</h4>

						<div style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.investingReducer.buildCostFreeSectorGround}
								unit='grond/m² perceel'
								decimals={2}
								editable={true}
								wide={true}
								prefix='€ '
								onValueChange={setInvestingValue('buildCostFreeSectorGround', dispatch)}
							/>
						</div>

						<div style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.investingReducer.buildCostFreeSectorFloor}
								unit='bouw/m² bvo'
								decimals={2}
								editable={true}
								wide={true}
								prefix='€ '
								onValueChange={setInvestingValue('buildCostFreeSectorFloor', dispatch)}
							/>
						</div>
					</ShowConditional>

					<ShowConditional condition={noHouses.buy > 0}>
						<h4>Koopwoningen</h4>

						<div style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.investingReducer.buildCostBuyGround}
								unit='grond/m²'
								decimals={2}
								editable={true}
								wide={true}
								prefix='€ '
								onValueChange={setInvestingValue('buildCostBuyGround', dispatch)}
							/>
						</div>

						<div style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.investingReducer.buildCostBuyFloor}
								unit='bouw/m²'
								decimals={2}
								editable={true}
								wide={true}
								prefix='€ '
								onValueChange={setInvestingValue('buildCostBuyFloor', dispatch)}
							/>
						</div>
					</ShowConditional>
				</div>
				<div>
					<p className="remark">Deze bedragen zijn automatisch geschat aan de hand van de gemeente waar het project gerealiseerd wordt.</p>
				</div>
			</div>

			<div className="hor-separator" />

			<h3><Icon icon='mdi:location' className='icon-blue' /> Erfpacht</h3>
			<div className="col-3">
				<div>
					<h4>Grondwaarde</h4>
					<CalculationTableRowTerm
						value={state.investingReducer.groundLeaseAmount}
						onValueChange={setInvestingValue('groundLeaseAmount', dispatch)}
						unit="totaal"
						editable={true}
						wide={true}
						decimals={2}
						prefix="€ " />
				</div>
				<div>
					<h4>Canonpercentage</h4>
					<CalculationTableRowTerm
						value={state.investingReducer.groundLeaseRate}
						onValueChange={setInvestingValue('groundLeaseRate', dispatch)}
						editable={true}
						wide={true}
						decimals={2}
						suffix=" %" />
				</div>
				<div>
					<h4>Indexatie</h4>
					<CalculationTableRowTerm
						value={state.investingReducer.groundLeaseIndexation}
						onValueChange={setInvestingValue('groundLeaseIndexation', dispatch)}
						unit=" per jaar"
						editable={true}
						wide={true}
						decimals={2}
						suffix=" %" />
				</div>
			</div>

			<p className="remark">Laat deze velden leeg, of zet canonpercentage op 0% als er geen erfpacht van toepassing is.</p>

			<div className="hor-separator" />

			<div>
				<h3><Icon icon='heroicons-outline:receipt-tax' className='icon-blue' /> Belasting</h3>
				<div className='col-3'>
					<div>
						<h4>OZB woningeigenaren</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.taxOZBPropertyOwners}
							unit='% van WOZ'
							editable={true}
							wide={true}
							decimals={4}
							prefix=''
							onValueChange={setInvestingValue('taxOZBPropertyOwners', dispatch)}
						/>
					</div>
					<div>
						<h4>Rioolheffing</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.taxSewageChargesPerHousePerYear}
							unit='woning/jaar'
							editable={true}
							wide={true}
							decimals={2}
							prefix='€ '
							onValueChange={setInvestingValue('taxSewageChargesPerHousePerYear', dispatch)}
						/>
					</div>
					<div>
						<h4>Watersysteemheffing</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.taxWaterSystemLevyPercentOfWOZ}
							unit='% van WOZ'
							editable={true}
							wide={true}
							decimals={4}
							prefix=''
							onValueChange={setInvestingValue('taxWaterSystemLevyPercentOfWOZ', dispatch)}
						/>
					</div>
				</div>
				<p className='remark'>
					Deze tarieven verschillen per gemeente, en zijn automatisch bepaald aan de hand van de gemeente waarin het project gerealiseerd wordt.
				</p>
			</div>

			<div className="hor-separator" />

			<div>
				<h3><Icon icon='fluent:wrench-16-filled' className='icon-blue' /> Onderhoud</h3>
				<div className="col-3">
					<CalculationTableRowTerm
						value={state.investingReducer.maintenancePerM2BvoPerYear}
						unit='per m² bvo per jaar'
						decimals={2}
						editable={true}
						wide={true}
						prefix='€ '
						onValueChange={setInvestingValue('maintenancePerM2BvoPerYear', dispatch)}
					/>
					<CalculationTableRowTerm
						value={state.serviceReducer.inflation}
						unit='% stijging per jaar'
						decimals={2}
						editable={true}
						wide={true}
						onValueChange={setServiceValue('inflation', dispatch)}
					/>
				</div>
				<p className='remark'>
					Het is aan te raden een Meerjarig Onderhoudsplan (MJOP) op te stellen waarin deze kosten beter in kaart worden gebracht
				</p>
			</div>

			<div className="hor-separator" />

			<div>
				<h3><Icon icon='fa-solid:handshake' className="icon-blue" /> Verzekering</h3>
				<div>
					<h4>Opstalverzekering</h4>
					<CalculationTableRowTerm
						value={state.investingReducer.insuranceCARPercent}
						suffix='%'
						unit='herbouwwaarde'
						editable={true}
						wide={true}
						decimals={2}
						onValueChange={setInvestingValue('insuranceCARPercent', dispatch)}
					/>
				</div>
				<p className='mt-4 remark'>
					De opstalverzekeringspremie wordt meestal berekend als percentage van de herbouwwaarde voor het deel van het gebouw dat verhuurd wordt.
				</p>
			</div>

			<ShowConditional condition={state.housingReducer.noHouses.social + state.housingReducer.noHouses.freeSector > 0}>
				<div className="hor-separator" />

				<h3><Icon icon='ant-design:user-switch-outlined' className="icon-blue" /> Mutatiekosten</h3>
				<div className="col-3">
					<div>
						<h4>Gemiddelde woonduur</h4>
						<CalculationTableRowTerm
							value={100 / state.investingReducer.mutationRatePercentPerYear}
							unit='jaar'
							editable={true}
							wide={true}
							decimals={1}
							prefix=''
							suffix=''
							onValueChange={({ floatValue }, { event }) => {
								if (event) {
									dispatch({
										type: 'investing/setValue',
										name: 'mutationRatePercentPerYear',
										value: 100 / floatValue,
									});
								}
							}}
						/>

					</div>
					<ShowConditional condition={state.housingReducer.noHouses.social > 0}>
						<h4>Sociale huur</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.mutationCostSocial}
							unit='per mutatie'
							editable={true}
							wide={true}
							decimals={2}
							prefix='€ '
							onValueChange={setInvestingValue('mutationCostSocial', dispatch)}
						/>
					</ShowConditional>
					<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
						<h4>Vrije sector huur</h4>
						<CalculationTableRowTerm
							value={state.investingReducer.mutationCostFreeSector}
							unit='per mutatie'
							editable={true}
							wide={true}
							decimals={2}
							prefix='€ '
							onValueChange={setInvestingValue('mutationCostFreeSector', dispatch)}
						/>
					</ShowConditional>
				</div>
				<p className='remark'>
					De mutatiekosten zijn kosten voor reparaties bij het vertrek van een huurder. Derving als gevolg van leegstand moet hierin <span className="font-bold">niet</span> worden meegerekend.
				</p>
			</ShowConditional>
		</Step>
	);
}
