import { createSelector } from 'reselect';

import { roundNumberToOneDecimal } from '../lib/rounding';
import { totalHousesAreaBvo } from './housingReducer';
import { bvoToVvoMultiplier } from './StepsStoreProvider';

export const initialFloors = 2;
export const initialSolarPanelDivisor = 1.6;
export const initialBuildingReducerState = {
	buildingReducer: {
		floors: 2,
		footprintOverwritten: undefined,
		commonAreaBvo: 100,
		commonAreaVvo: 80,
		totalHousesAreaVvoOverwritten: undefined,
		rooftopAreaNetOverwritten: undefined,
		solarPanelsOverwritten: undefined,
		solarPanelsDivisior: 1.6,
	},
};
export function buildingReducer(state, action) {
	switch (action.type) {
	case 'building/setTotalHousesAreaBvo': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				totalHousesAreaVvoOverwritten: action.value,
			},
		};
	}
	case 'building/setHousesAreaMultiplier': {
		return action.value === roundNumberToOneDecimal(housesAreaBvoMultiplier(state))
			? state
			: {
				...state,
				buildingReducer: {
					...state.buildingReducer,
					totalHousesAreaVvoOverwritten: totalHousesAreaBvo(state) * action.value,
				},
				  };
	}
	case 'building/setCommonAreaBvo': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				commonAreaBvo: action.value,
				commonAreaVvo: state.buildingReducer.commonAreaBvo * housesAreaBvoMultiplier(state) == state.buildingReducer.commonAreaVvo ? action.value * housesAreaBvoMultiplier(state) : state.buildingReducer.commonAreaVvo,
			},
		};
	}

	case 'building/setCommonAreaBvoMultiplier': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				commonAreaVvo: state.buildingReducer.commonAreaBvo * action.value,
			},
		};
	}

	case 'building/setCommonAreaVvo': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				commonAreaVvo: action.value,
			},
		};
	}

	case 'building/setRooftopAreaNetOverwritten': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				rooftopAreaNetOverwritten: action.value,
			},
		};
	}

	case 'building/setRooftopAreaNetMultiplier': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				rooftopAreaNetOverwritten: state.buildingReducer.rooftopAreaNetOverwritten * action.value,
			},
		};
	}

	case 'building/setSolarPanels': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				solarPanelsDivisior: rooftopAreaNet(state) / action.value,
				solarPanelsOverwritten: action.value,
			},
		};
	}

	case 'building/setSolarPanelsDivisor': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				solarPanelsDivisior: action.value,
				solarPanelsOverwritten: rooftopAreaNet(state) / action.value,
			},
		};
	}

	case 'building/setFloors': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				floors: action.value,
			},
		};
	}

	case 'building/setBuildingFootprint': {
		return {
			...state,
			buildingReducer: {
				...state.buildingReducer,
				footprintOverwritten: action.value,
			},
		};
	}

	default: {
		return state;
	}
	}
}

export const building = (state) => state.buildingReducer;
export const buildingAreaTotalBvo = createSelector(
	totalHousesAreaBvo,
	building,
	(totalHousesAreaBvo, building) => totalHousesAreaBvo + building.commonAreaBvo
);
export const buildingFootprint = createSelector(
	buildingAreaTotalBvo,
	building,
	(buildingAreaTotalBvo, building) => building.footprintOverwritten ?? buildingAreaTotalBvo / building.floors
);

export const totalHousesAreaVvo = createSelector(
	totalHousesAreaBvo,
	building,
	(totalHousesAreaBvo, building) => building.totalHousesAreaVvoOverwritten ?? totalHousesAreaBvo * bvoToVvoMultiplier
);

export const housesAreaBvoMultiplier = createSelector(
	totalHousesAreaBvo,
	totalHousesAreaVvo,
	(totalHousesAreaBvo, totalHousesAreaVvo) => totalHousesAreaVvo / totalHousesAreaBvo
);

export const commonAreaBvoMultiplier = createSelector(
	building,
	(building) => building.commonAreaVvo / building.commonAreaBvo
);

export const rooftopAreaNet = createSelector(
	buildingFootprint,
	building,
	(buildingFootprint, building) => building.rooftopAreaNetOverwritten ?? buildingFootprint * 0.7
);

export const rooftopAreaNetMultiplier = createSelector(
	rooftopAreaNet,
	buildingFootprint,
	(rooftopAreaNet, buildingFootprint) => rooftopAreaNet / buildingFootprint
);

export const solarPanels = createSelector(
	rooftopAreaNet,
	building,
	(rooftopAreaNet, building) => building.solarPanelsOverwritten ?? rooftopAreaNet / building.solarPanelsDivisior
);
//

export const buildingAreaTotalVvo = createSelector(
	totalHousesAreaVvo,
	building,
	(totalHousesAreaVvo, building) => totalHousesAreaVvo + building.commonAreaVvo
);

export const floors = createSelector(building, (building) => building.floors);

export const houseAreaBvoPerFloor = createSelector(floors, totalHousesAreaBvo, (floors, totalBvo) => totalBvo / floors);
