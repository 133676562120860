import { useContext, useMemo } from 'react';

import {
	buildingAreaTotalBvo,
	buildingFootprint,
	commonAreaBvoMultiplier,
	floors,
} from '../../../reducers/buildingReducer';
import { totalHousesAreaBvo } from '../../../reducers/housingReducer';
import { BouwCalculatorReducerContext, bvoToVvoMultiplier } from '../../../reducers/StepsStoreProvider';
import { CalculationTableRow } from '../../lib/calctable/CalculationTableRow';
import { CalculationTableRowTerm } from '../../lib/calctable/CalculationTableRowTerm';
import { hasValidValues, Step } from '../Step';
import { TERRAIN_STEP } from '../s4-terrain/TerrainStep';

export const BUILDING_STEP = 'building';

export function BuildingStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	const housing = totalHousesAreaBvo(state);

	return (
		<Step
			name='Gebouw'
			id={BUILDING_STEP}
			description='Welke eigenschappen heeft het gebouw?'
			icon='clarity:building-solid'
			showContent={state.stepReducer.step === BUILDING_STEP}
			nextStepValidations={[]}
			thisStep={BUILDING_STEP}
			nextStep={TERRAIN_STEP}
			summaryInError={hasValidValues([ totalHousesAreaBvo(state), buildingFootprint(state) ])}
			summaryComponent={
				<div>
					<div>
						<span className='bold-value'>{buildingAreaTotalBvo(state).toFixed(0)}</span>
						<span> m² bruto</span>
						<span> totale gebouwvloeroppervlakte</span>
					</div>
					<div>
						<span className='bold-value'>{buildingFootprint(state).toFixed(0)}</span>
						<span> m² bruto footprint</span>
					</div>
				</div>
			}>
			<CalculationTableRow
				title='Gemeenschappelijke ruimte'
				helpText='Dit is alles wat een niet onder een huurder geschaard kan worden, maar wel gebouwd moet worden. Denk aan entree, gangen, trappen, maar ook ontmoetingsplekken etc.'>
				<CalculationTableRowTerm
					value={state.buildingReducer.commonAreaBvo}
					unit='m² bvo'
					editable={true}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'building/setCommonAreaBvo',
								value: floatValue,
							});
						}
					}} />
				<CalculationTableRowTerm
					sign='*'
					value={commonAreaBvoMultiplier(state)}
					decimals={2}
					editable={false}
					unit="vormfactor"
					initialValue={bvoToVvoMultiplier}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'building/setCommonAreaBvoMultiplier',
								value: floatValue,
							});
						}
					}} />
				<CalculationTableRowTerm
					sign="="
					value={state.buildingReducer.commonAreaVvo}
					unit='m² gebr. op.'
					editable={true}
					initialValue={useMemo(
						() => state.buildingReducer.commonAreaBvo * bvoToVvoMultiplier,
						[ state.buildingReducer.commonAreaBvo ]
					)}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'building/setCommonAreaVvo',
								value: floatValue,
							});
						}
					}} />
			</CalculationTableRow>

			<div className="hor-separator" />

			<CalculationTableRow title='Footprint'>
				<CalculationTableRowTerm
					title='Woningoppervlakte'
					value={buildingAreaTotalBvo(state)}
					unit='m² bvo' />
				<CalculationTableRowTerm
					sign='/'
					value={floors(state)}
					unit='woonlagen'
					editable={true}
					mustBeInteger={false}
					decimals={1}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'building/setFloors',
								value: floatValue,
							});
						}
					}} />
				{/* eslint-disable-next-line */}
				<CalculationTableRowTerm
					sign="="
					value={buildingFootprint(state)}
					unit='m² footprint'
					editable={true}
					id='footprint'
					initialValue={useMemo(() => buildingAreaTotalBvo(state) / 2, [ housing ])}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'building/setBuildingFootprint',
								value: parseInt(floatValue, 10),
							});
						}
					}} />
			</CalculationTableRow>

			<div style={{ maxWidth: '200px', height: '150px', display: 'flex', alignItems: 'flex-end' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column-reverse',
						width: '100%',
						overflowX: 'hidden',
						overflowY: 'visible',
						paddingTop: '8px',
						marginTop: '12px',
					}}>
					{Array.apply(null, Array(Math.min(Math.ceil(floors(state)), 4) || 0)).map((_, idx, arr) => (
						<div
							key={idx}
							style={{
								flex: 1,
								minHeight: '32px',
								lineHeight: '30px',
								boxShadow:
									'0 0 1px inset rgba(0, 0, 0, 0.5)' +
									(idx >= 3 && floors(state) > 4 ? ', 0 -4px 4px #818CF8' : ''),
								background: '#818CF8',
								color: 'white',
								textAlign: 'center',
								borderBottom: idx > 0 ? 'none' : '4px dashed #312D81',
							}}
							className='text-sm font-semibold'>
							{idx >= 3 && floors(state) > 4 && <span>3e - {floors(state) - 1}e</span>}
							{idx >= 3 && floors(state) <= 4 && <span>3e verdieping</span>}
							{idx < 3 && <span>{idx === 0 ? 'Begane grond' : idx + 'e verdieping'}</span>}
						</div>
					))}
				</div>
			</div>
		</Step>
	);
}
