import { Icon } from '@iconify/react';
import { useContext, useState } from 'react';
import { classNames } from '../../../lib/classNames';

import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CalculationInput } from '../../lib/calctable/CalculationInput';
import von from '../../lib/custom-icons/Building-pana 1.svg';
import logo from '../../lib/custom-icons/Building-rafiki.svg';
import construction from '../../lib/custom-icons/Construction-rafiki.svg';
import inflation from '../../lib/custom-icons/Investing-rafiki.svg';
import manageMoney from '../../lib/custom-icons/Manage money-pana.svg';
import { Step } from '../Step';
import { RESULT_STEP } from '../s10-results/ResultSection';

export const VALUE_STEP = 'value';

function setValueValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'value/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

function ValueBlockExperiment({ title, subtitle, children, icon }) {
	const [ editable, setEditable ] = useState(false);

	return <div className={classNames('value-block', editable && 'value-block-editing')}>
		<div className="value-block-edit-btn">
			{!editable
				? <button type="button" onClick={() => setEditable(!editable)}><Icon icon="line-md:pencil-twotone-alt" /></button>
				: <button type="button" onClick={() => setEditable(!editable)}><Icon icon="fluent:checkmark-32-filled" /></button>}
		</div>
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<img src={icon} alt={title} style={{ width: '60%', height: 'auto' }} />
			<h2>{title}</h2>
			<h3>{subtitle}</h3>
		</div>
		<div>{children(editable)}</div>
	</div>;
}

function ValueBlockInput({ children, unit }) {
	return (
		<div style={{ display: 'flex', alignItems: 'baseline' }}>
			<div>{children}</div>
			<div className="unit">{unit}</div>
		</div>
	);
}

function ValueBlockDescription({ title, children }) {
	return (
		<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 'var(--u-16)', alignItems: 'baseline' }}>
			<div style={{ textAlign: 'right' }}>{title}:</div>
			<div style={{ textAlign: 'left' }}>{children}</div>
		</div>
	);
}

export function ValuationStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	return (
		<>
			<Step
				name='Waarde'
				id={VALUE_STEP}
				thisStep={VALUE_STEP}
				wider={true}
				description='Waarde van het gebouw automatisch berekend op basis van de gemeente'
				icon='bi:cash-coin'
				showContent={state.stepReducer.step === VALUE_STEP}
				nextStep={RESULT_STEP}
				readyForNextStep={true}
				summaryComponent={
					<span>
						Waarden van het gebouw uit{' '}
						<span className='bold-value'>{state.housingReducer.municipality?.name}</span>
					</span>
				}>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 'var(--u-32)' }}>
					<ValueBlockExperiment
						icon={logo}
						title='WOZ-Waarde'
						subtitle={"Waardering volgens wet WOZ in " + state.housingReducer.municipality?.name}>
						{(editable) => (
							<>
								{state.housingReducer.noHouses.social > 0 && <ValueBlockDescription title='Sociale huur'>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.WOZSocialPerM2}
											prefix='€ '
											unit='per m²'
											onValueChange={setValueValue('WOZSocialPerM2', dispatch)}
											editable={editable}
											decimals={2}
											textLeft={true}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}

								{state.housingReducer.noHouses.freeSector > 0 && <ValueBlockDescription title='Vrije sector'>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.WOZFreeSectorPerM2}
											prefix='€ '
											unit='per m²'
											onValueChange={setValueValue('WOZFreeSectorPerM2', dispatch)}
											editable={editable}
											decimals={2}
											textLeft={true}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}

								{state.housingReducer.noHouses.buy > 0 && <ValueBlockDescription title='Koop'>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.WOZBuyPerM2}
											prefix='€ '
											editable={editable}
											decimals={2}
											textLeft={true}
											unit='per m²'
											onValueChange={setValueValue('WOZBuyPerM2', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}
							</>
						)}
					</ValueBlockExperiment>
					<ValueBlockExperiment
						icon={von}
						title='VON-Waarde'
						subtitle={'Vrij-Op-Naam-waarde in ' + state.housingReducer.municipality?.name}>
						{(editable) => (
							<>
								{state.housingReducer.noHouses.social > 0 && <ValueBlockDescription title='Sociale huur'>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.VONSocialPerM2}
											prefix='€ '
											decimals={2}
											editable={editable}
											textLeft={true}
											onValueChange={setValueValue('VONSocialPerM2', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}

								{state.housingReducer.noHouses.freeSector > 0 && <ValueBlockDescription title='Vrije sector'>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.VONFreeSectorPerM2}
											prefix='€ '
											decimals={2}
											editable={editable}
											textLeft={true}
											onValueChange={setValueValue('VONFreeSectorPerM2', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}

								{state.housingReducer.noHouses.buy > 0 && <ValueBlockDescription title='Koop'>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.VONBuyPerM2}
											prefix='€ '
											decimals={2}
											editable={editable}
											textLeft={true}
											onValueChange={setValueValue('VONBuyPerM2', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}
							</>
						)}
					</ValueBlockExperiment>
					<ValueBlockExperiment icon={manageMoney} title='Huurwaarde' subtitle='Kapitalisatiefactor'>
						{(editable) => (
							<>
								{state.housingReducer.noHouses.social > 0 && <ValueBlockDescription title='Sociale huur'>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.VONBuyPerM2}
											prefix='€ '
											decimals={2}
											editable={editable}
											textLeft={true}
											onValueChange={setValueValue('VONBuyPerM2', dispatch)}
										/>
									</ValueBlockInput>
									<ValueBlockInput unit='jaar kapitalisatiefactor'>
										<CalculationInput
											value={15}
											decimals={0}
											editable={editable}
											textLeft={true}
											onValueChange={setValueValue('VONBuyPerM2', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}

								{state.housingReducer.noHouses.freeSector > 0 && <ValueBlockDescription title='Vrije sector'>
									<ValueBlockInput unit='per m²'>
										<CalculationInput
											value={state.valueReducer.VONBuyPerM2}
											prefix='€ '
											decimals={2}
											editable={editable}
											textLeft={true}
											onValueChange={setValueValue('VONBuyPerM2', dispatch)}
										/>
									</ValueBlockInput>
									<ValueBlockInput unit='jaar kapitalisatiefactor'>
										<CalculationInput
											value={15}
											decimals={0}
											editable={editable}
											textLeft={true}
											onValueChange={setValueValue('VONBuyPerM2', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}
							</>
						)}
					</ValueBlockExperiment>
					<ValueBlockExperiment
						icon={inflation}
						title='Waardestijging'
						subtitle='Verwachte jaarlijkse waardestijging'>
						{(editable) => (
							<>
								{state.housingReducer.noHouses.social > 0 && <ValueBlockDescription title='Sociale huur'>
									<ValueBlockInput unit='per jaar'>
										<CalculationInput
											value={state.valueReducer.inflationSocialPerYear}
											suffix='%'
											editable={editable}
											decimals={1}
											textLeft={true}
											onValueChange={setValueValue('inflationSocialPerYear', dispatch)}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}

								{state.housingReducer.noHouses.freeSector > 0 && <ValueBlockDescription title='Vrije sector'>
									<ValueBlockInput unit='per jaar'>
										<CalculationInput
											value={state.valueReducer.inflationFreeSectorPerYear}
											onValueChange={setValueValue('inflationFreeSectorPerYear', dispatch)}
											suffix='%'
											editable={editable}
											decimals={1}
											textLeft={true}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}

								{state.housingReducer.noHouses.buy > 0 && <ValueBlockDescription title='Koop'>
									<ValueBlockInput unit='per jaar'>
										<CalculationInput
											value={state.valueReducer.inflationBuyPerYear}
											onValueChange={setValueValue('inflationBuyPerYear', dispatch)}
											suffix='%'
											editable={editable}
											decimals={1}
											textLeft={true}
										/>
									</ValueBlockInput>
								</ValueBlockDescription>}
							</>
						)}
					</ValueBlockExperiment>
				</div>
			</Step>
		</>
	);
}
