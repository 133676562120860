import { useContext } from 'react';

import {
	incomeFreeSector,
	incomeFromCommonArea,
	incomeSocial,
	totalIncomeSecondYear,
} from '../../../reducers/calculations/incomeCalculation';
import { totalHousesAreaBvo } from '../../../reducers/housingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CostColumn } from '../../lib/costrow/CostColumn';
import { CostInput } from '../../lib/costrow/CostInput';
import { CostRow } from '../../lib/costrow/CostRow';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { LossIcon } from '../../lib/custom-icons/LossIcon';
import { SolarPanelIcon } from '../../lib/custom-icons/SolarPanelIcon';
import { Step } from '../Step';
import { FINANCE_STEP } from '../s8-financing/FinancingStep';
import { rentalIncomeFreeSectorPerMonth, rentalIncomeSocialPerMonth } from '../../../reducers/incomeReducer';
import ShowConditional from '../../lib/ShowConditional';

export const INCOME_STEP = 'income';

function setIncomeValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'income/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

export function IncomeStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	const housing = totalHousesAreaBvo(state);

	const { noHouses } = state.housingReducer;

	return (
		<Step
			name='Inkomsten'
			id={INCOME_STEP}
			wider={true}
			description='Bepaal de huurprijzen en andere inkomstenfactoren in het project'
			icon='bi:cash-coin'
			showContent={state.stepReducer.step === INCOME_STEP}
			nextStepValidations={[
				{ valid: noHouses.social == 0 || rentalIncomeSocialPerMonth(state) >= 0, error: 'Huur sociale sector moet ingevuld zijn' },
				{ valid: noHouses.social == 0 || state.incomeReducer.rentalIncomeSocialInflationPerYear >= 0, error: 'Huurstijging sociale sector moet ingevuld zijn' },
				{ valid: noHouses.freeSector == 0 || rentalIncomeFreeSectorPerMonth(state) >= 0, error: 'Huur vrije sector moet ingevuld zijn' },
				{ valid: noHouses.freeSector == 0 || state.incomeReducer.rentalIncomeFreeSectorInflationPerYear >= 0, error: 'Huurstijging vrije sector moet ingevuld zijn' },
			]}
			nextStep={FINANCE_STEP}
			thisStep={INCOME_STEP}
			summaryComponent={
				<div className="col-2">
					<div>
						<div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
							<div>Inkomsten</div>
							<div>
								<FormattedNumber
									value={(totalIncomeSecondYear(state) + incomeFromCommonArea(state)) || 0}
									prefix='€ '
									className='bold-value'
									decimalScale={0} />{' '}
								/ jaar
							</div>
						</div>
						<ShowConditional condition={state.housingReducer.noHouses.social > 0}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Sociale huur:</div>
								<div>
									<FormattedNumber
										className='bold-value'
										value={incomeSocial(state)}
										prefix='€ '
										decimalScale={0} />{' '}
									/ jaar
								</div>
							</div>
						</ShowConditional>
						<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Vrije sector:</div>
								<div>
									<FormattedNumber
										className='bold-value'
										value={incomeFreeSector(state)}
										prefix='€ '
										decimalScale={0} />{' '}
									/ jaar
								</div>
							</div>
						</ShowConditional>
						<ShowConditional condition={state.buildingReducer.commonAreaBvo > 0}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Algemene ruimte:</div>
								<div>
									<FormattedNumber
										className='bold-value'
										value={incomeFromCommonArea(state) || 0}
										prefix='€ '
										decimalScale={0} />{' '}
									/ jaar
								</div>
							</div>
						</ShowConditional>
					</div>
				</div>
			}>
			<CostRow cols={3} name='Huuropbrengsten' helpText="De huuropbrengsten zijn automatisch ingeschat aan de hand van de gemeente die je hebt ingevuld.">
				{noHouses.social > 0 && <CostColumn name='Sociale huur'>
					<CostInput
						value={rentalIncomeSocialPerMonth(state)}
						prefix='€ '
						unit='per maand'
						helpText='gewogen gemiddelde'
						onValueChange={setIncomeValue('rentalIncomeSocialPerMonth', dispatch)} />
					<CostInput
						value={state.incomeReducer.rentalIncomeSocialInflationPerYear}
						suffix='%'
						unit='stijging'
						helpText='maximaal met inflatie'
						onValueChange={setIncomeValue('rentalIncomeSocialPerMonth', dispatch)}
					/>
					<p className="remark" style={{ lineHeight: '1.2em' }}>
						<a
							className='text-blue-500 underline'
							href='https://www.huurcommissie.nl/onderwerpen/huurprijs-en-punten/nieuwe-huurprijscheck/huurprijscheck-zelfstandige-woonruimte'>
							Huurprijscheck: is deze huurprijs realistisch?
						</a>
					</p>
				</CostColumn>}
				{noHouses.freeSector > 0 && <CostColumn name='Vrije sector'>
					<CostInput
						value={rentalIncomeFreeSectorPerMonth(state)}
						prefix='€ '
						unit='per maand'
						helpText='gewogen gemiddelde'
						onValueChange={setIncomeValue('rentalIncomeFreeSectorPerMonth', dispatch)}
					/>
					<CostInput
						value={state.incomeReducer.rentalIncomeFreeSectorInflationPerYear}
						suffix='%'
						unit='stijging'
						helpText='maximaal met inflatie'
						onValueChange={setIncomeValue('rentalIncomeFreeSectorInflationPerYear', dispatch)}
					/>
				</CostColumn>}
				{state.buildingReducer.commonAreaBvo > 0 && <CostColumn name='Gemeenschappelijke ruimte'>
					<CostInput
						value={state.incomeReducer.rentalIncomeCommonAreaPerDayPart}
						prefix='€ '
						unit='per dagdeel'
						helpText='bijvoorbeeld zaalverhuur aan buurtinitiatief'
						onValueChange={setIncomeValue('rentalIncomeCommonAreaPerDayPart', dispatch)}
					/>
					<CostInput
						value={state.incomeReducer.rentalIncomeCommonAreaTimesPerYear}
						unit='x per jaar'
						decimals={0}
						onValueChange={setIncomeValue('rentalIncomeCommonAreaTimesPerYear', dispatch)}
					/>
					<CostInput
						value={state.incomeReducer.rentalIncomeCommonAreaInflationPerYear}
						suffix='%'
						unit='stijging'
						onValueChange={setIncomeValue('rentalIncomeCommonAreaInflationPerYear', dispatch)}
					/>
				</CostColumn>}
			</CostRow>

			<div className="hor-separator" />

			{noHouses.social > 0 && <CostRow name='Energieprestatievergoeding'>
				<CostColumn
					mdSpan='md:col-span-6 pr-12'
					name='Per woning'>
					<CostInput
						value={state.incomeReducer.energyPerformanceFeePerM2VVoPerHouse}
						unit='per m² woonoppervlak'
						onValueChange={setIncomeValue('energyPerformanceFeePerM2VVoPerHouse', dispatch)} />
					<CostInput
						value={state.incomeReducer.energyPerformanceFeePerM2VVoInflationPerYear}
						suffix='%'
						unit='stijging'
						onValueChange={setIncomeValue('energyPerformanceFeePerM2VVoInflationPerYear', dispatch)} />

					<div>
						<p className="remark">
							De energieprestatievergoeding (EPV) is een vergoeding die in rekening gebracht mag worden bij
							energiezuinige sociale huurwoningen. Deze regeling is niet van toepassing op vrije sector huur.&nbsp;
							<a href="https://www.rijksoverheid.nl/onderwerpen/energie-thuis/vraag-en-antwoord/maximale-energieprestatievergoeding-epv-huurwoning">Bekijk hier
							hoe dit bedrag ingeschat moet worden.</a>
						</p>
					</div>
				</CostColumn>
			</CostRow>}

			<div className="hor-separator" />

			<CostRow name='Derving'>
				<CostColumn name='Leegstand'>
					<CostInput
						value={state.incomeReducer.lossToVacantHousesPercentOfTheRentalIncomeServiceCostsAndEnergyPerformanceFee}
						suffix='%'
						helpText={' van de huur, servicekosten' + (noHouses.social > 0 ? ' en energieprestatievergoeding' : '')}
						onValueChange={setIncomeValue(
							'lossToVacantHousesPercentOfTheRentalIncomeServiceCostsAndEnergyPerformanceFee',
							dispatch
						)}
					/>
				</CostColumn>
			</CostRow>
		</Step>
	);
}
