import { createSelector } from 'reselect';

import { housing } from './housingReducer';

export function valueReducer(state, action) {
	if (action.type === 'value/setValue') {
		return {
			...state,
			valueReducer: {
				...state.valueReducer,
				[action.name]: action.value,
			},
		};
	}
	if (action.type == 'housing/setMunicipality') {
		if (action.municipality == null) return state;
		return {
			...state,
			valueReducer: {
				...state.valueReducer,
				WOZSocialPerM2: action.municipality.wozValue.social,
				WOZFreeSectorPerM2: action.municipality.wozValue.freeSector,
				WOZBuyPerM2: action.municipality.wozValue.buy,
				VONSocialPerM2: action.municipality.vonValue.social,
				VONFreeSectorPerM2: action.municipality.vonValue.freeSector,
				VONBuyPerM2: action.municipality.vonValue.buy,
			},
		};
	}
	return state;
}

export const initialValueReducer = {
	valueReducer: {
		WOZSocialPerM2: 3000,
		WOZFreeSectorPerM2: 3200,
		WOZBuyPerM2: 3500,

		VONSocialPerM2: 3500,
		VONFreeSectorPerM2: 3800,
		VONBuyPerM2: 3800,

		// rebuildValueSocialPerM2: 2900,
		// rebuildValueFreeSectorPerM2: 3300,
		// rebuildValueBuyPerM2: 3500,

		inflationSocialPerYear: 2,
		inflationFreeSectorPerYear: 2,
		inflationBuyPerYear: 2,
	},
};

export const value = (state) => state.valueReducer;
export const totalWOZSocial = createSelector(
	housing,
	value,
	(housing, value) => housing.noHouses.social * housing.areaVvo.social * value.WOZSocialPerM2
);

export const totalWOZFreeSector = createSelector(
	housing,
	value,
	(housing, value) => housing.noHouses.freeSector * housing.areaVvo.freeSector * value.WOZFreeSectorPerM2
);

export const totalWOZBuy = createSelector(
	housing,
	value,
	(housing, value) => housing.noHouses.buy * housing.areaVvo.buy * value.WOZBuyPerM2
);
