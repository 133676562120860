import { Icon } from '@iconify/react';
import { cloneElement, useContext } from 'react';
import { classNames } from '../../../lib/classNames';

import {
	administratorTotalYearCosts,
	cleaningCommonAreaTotalYearCosts,
	electricityTotalYearCostsCommonArea,
	electricityTotalYearCostsFreeSector,
	electricityTotalYearCostsSocial,
	heatingTotalYearCostsCommonArea,
	heatingTotalYearCostsFreeSector,
	heatingTotalYearCostsSocial,
	houseMasterTotalYearCosts,
	serviceCostsCommonArea,
	serviceCostsFreeSector,
	serviceCostsSocialHousing,
	totalAlarmCosts,
	totalAlarmCostsFreeSector,
	totalAlarmCostsSocial,
	totalElectricity,
	totalHeating,
	totalPersonnelYearCosts,
	totalServiceCostsHuurcommissie,
	totalServiceCostsReal,
	totalServiceCostsVsHuurcommissieRatio,
} from '../../../reducers/serviceReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CostColumn } from '../../lib/costrow/CostColumn';
import { CostInput } from '../../lib/costrow/CostInput';
import { CostRow } from '../../lib/costrow/CostRow';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { HeatingHouseIcon } from '../../lib/custom-icons/HeatingHouseIcon';
import { HousekeeperIcon } from '../../lib/custom-icons/HousekeeperIcon';
import { MaintenanceIcon } from '../../lib/custom-icons/MaintenanceIcon';
import { SirenIcon } from '../../lib/custom-icons/SirenIcon.js';
import { Step } from '../Step';
import { INCOME_STEP } from '../s7-income/IncomeStep';
import { Alert, AlertType } from '../../lib/Alert';

export const SERVICE_STEP = 'service';

export function setServiceValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'service/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

const curlyBraceStyle = {
	borderStyle: 'solid',
	borderWidth: '0px 30px 0px 0px',
	borderImage: 'url(/Curly_bracket_right.svg) 1 100 stretch',
};

// {name, month, year}, total

function ServiceMixSummary({ items, total }) {
	return <div className="service-mix-summary">
		<div className="service-mix-summary-row service-mix-summary-header">
			<div />
			<div>Maand</div>
			<div>Jaar</div>
		</div>
		{items.map((item, idx) => <div key={idx} className="service-mix-summary-row">
			<div>{item.name}</div>
			<div>
				<FormattedNumber value={item.month} prefix='€ ' decimalScale={0} />
			</div>
			<div>
				<FormattedNumber value={item.year} prefix='€ ' decimalScale={0} />
			</div>
		</div>)}
		<div className='service-mix-summary-row service-mix-summary-footer'>
			<div>Totaal</div>
			<div><FormattedNumber value={total.month} prefix='€ ' className='font-medium' decimalScale={0} /></div>
			<div><FormattedNumber value={total.year} prefix='€ ' className='font-medium' decimalScale={0} /></div>
		</div>
	</div>;
}

function ServiceCostComponent({ icon, title, remark, disabledText, summary, children, isEnabled, setEnabled }) {
	return <div className={classNames('service-cost', !isEnabled && 'service-cost-disabled')}>
		<div className="service-cost-header">
			<div className="service-cost-icon">{icon}</div>
			<div>
				<h3>{title}</h3>
				<span className='remark'>{remark}</span>
			</div>
		</div>

		<div className="service-cost-content">
			<div className="service-cost-content-checkbox">
				<input
					type='checkbox'
					checked={isEnabled}
					onChange={() => setEnabled()} />
			</div>
			{isEnabled ? <div className="service-cost-fields">
				{children}
				{summary}
			</div> : <div className="service-cost-disabled-text">{disabledText}</div>}
		</div>
	</div>;
}

export function ServiceCostStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	return (
		<Step
			name='Servicekosten'
			id={SERVICE_STEP}
			wider={true}
			description='Welke diensten zijn er centraal geregeld, en moeten dus via de servicekosten of VvE-bijdrage verrekend worden?'
			icon='ic:twotone-cleaning-services'
			showContent={state.stepReducer.step === SERVICE_STEP}
			nextStep={INCOME_STEP}
			thisStep={SERVICE_STEP}
			readyForNextStep={true}
			summaryComponent={
				<div className="col-2">
					<div>
						<div style={{ borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Operatiekosten</div>
								<div>
									<FormattedNumber
										value={totalServiceCostsReal(state) || 0}
										prefix='€ '
										className='bold-value'
										decimalScale={0}
									/>{' '}
									/ jaar
								</div>
							</div>
							{totalServiceCostsHuurcommissie(state) > 0 && (
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>Max. volgens huurcommissie</div>
									<div style={{ textAlign: 'right' }}>
										<FormattedNumber
											value={totalServiceCostsHuurcommissie(state)}
											prefix='€ '
											className='bold-value'
											decimalScale={0}
										/>{' '}
										/ jaar<br />
										<small>
											(<FormattedNumber value={totalServiceCostsVsHuurcommissieRatio(state) * 100} decimalScale={1} suffix='%' />)
										</small>
									</div>
								</div>
							)}
						</div>
						{state.housingReducer.noHouses.social > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>Sociale huur:</div>
							<div>
								<FormattedNumber
									className='bold-value'
									value={serviceCostsSocialHousing(state) || 0}
									prefix='€ '
									decimalScale={0}
								/>{' '}
								/ jaar
							</div>
						</div>}
						{state.housingReducer.noHouses.freeSector > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>Vrije sector:</div>
							<div>
								<FormattedNumber
									className='bold-value'
									value={serviceCostsFreeSector(state) || 0}
									prefix='€ '
									decimalScale={0}
								/>{' '}
								/ jaar
							</div>
						</div>}{' '}
						{state.buildingReducer.commonAreaBvo > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>Algemene ruimte:</div>
							<div>
								<FormattedNumber
									className='bold-value'
									value={serviceCostsCommonArea(state) || 0}
									prefix='€ '
									decimalScale={0}
								/>{' '}
								/ jaar
							</div>
						</div>}
					</div>
					<div>
						<div style={{ borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Operatiekosten</div>
								<div>
									<FormattedNumber
										value={(totalServiceCostsReal(state) || 0) / 12}
										prefix='€ '
										className='bold-value'
										decimalScale={0} />{' '}
									/ maand
								</div>
							</div>
						</div>
						{state.housingReducer.noHouses.social > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>Sociale huur:</div>
							<div>
								<FormattedNumber
									className='bold-value'
									value={(serviceCostsSocialHousing(state) || 0) / 12}
									prefix='€ '
									decimalScale={0} />{' '}
								/ maand
							</div>
						</div>}
						{state.housingReducer.noHouses.freeSector > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>Vrije sector:</div>
							<div>
								<FormattedNumber
									className='bold-value'
									value={(serviceCostsFreeSector(state) || 0) / 12}
									prefix='€ '
									decimalScale={0} />{' '}
								/ maand
							</div>
						</div>}{' '}
						{state.buildingReducer.commonAreaBvo > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>Algemene ruimte:</div>
							<div>
								<FormattedNumber
									className='bold-value'
									value={(serviceCostsCommonArea(state) || 0) / 12}
									prefix='€ '
									decimalScale={0} />{' '}
								/ maand
							</div>
						</div>}
					</div>
				</div>
			}>

			<Alert type={AlertType.Info} title="Overslaan?" text="Servicekosten hebben geen impact op de haalbaarheid van het project, maar geven wel een indruk van wat de huurder maandelijks kwijt gaat zijn. Als je niet zeker weet wat je moet invullen dan kun je deze stap overslaan." />

			<div className="hor-separator" />

			<ServiceCostComponent
				icon={<div style={{ zoom: '150%' }}><Icon icon='mdi:home-lightning-bolt' /></div>}
				title='Elektriciteitsverbruik'
				remark='Specificeer hier het elektriciteitsverbruik voor huurders, en/ of de gemeenschappelijke ruimten'
				isEnabled={state.serviceReducer.electricityEnabled}
				setEnabled={() => dispatch({ type: 'service/toggleElectricityEnabled' })}
				disabledText='Het elektriciteitsverbruik wordt niet meegenomen, en wordt door de huurders zelf betaald'
				summary={
					<ServiceMixSummary
						items={[
							{
								name: 'Sociale huur',
								year: electricityTotalYearCostsSocial(state),
								month: electricityTotalYearCostsSocial(state) / 12,
							},
							{
								name: 'Vrije sector',
								year: electricityTotalYearCostsFreeSector(state),
								month: electricityTotalYearCostsFreeSector(state) / 12,
							},
							{
								name: 'Gemeenschappelijk',
								year: electricityTotalYearCostsCommonArea(state),
								month: electricityTotalYearCostsCommonArea(state) / 12,
							},
						]}
						total={{
							year: totalElectricity(state),
							month: totalElectricity(state) / 12,
						}}
					/>
				}>
				<div style={{ ...curlyBraceStyle }} className="col-2">
					<div>
						<h4>Per woning</h4>
						<CostInput
							value={state.serviceReducer.electricityKWhHousePerYear}
							unit='kWh/ woning'
							onValueChange={setServiceValue('electricityKWhHousePerYear', dispatch)} />
						<CostInput
							value={state.serviceReducer.electricityHousePricePerKWh}
							unit='per kWh'
							prefix='€ '
							onValueChange={setServiceValue('electricityHousePricePerKWh', dispatch)} />
					</div>
					<div>
						<h4>Gemeenschappelijke ruimte</h4>

						<CostInput
							value={state.serviceReducer.electricityKWhCommonAreaPerM2}
							unit='kWh/ m²'
							onValueChange={setServiceValue('electricityKWhCommonAreaPerM2', dispatch)} />
						<CostInput
							value={state.serviceReducer.electricityCommonAreaPricePerKWh}
							unit='per kWh'
							prefix='€ '
							onValueChange={setServiceValue('electricityCommonAreaPricePerKWh', dispatch)} />
					</div>
				</div>
			</ServiceCostComponent>

			<div className="hor-separator" />

			<ServiceCostComponent
				icon={<HeatingHouseIcon />}
				isEnabled={state.serviceReducer.heatingEnabled}
				setEnabled={() => dispatch({ type: 'service/toggleHeatingEnabled' })}
				remark='Specificeer hier het warmteverbruik voor huurders, en/ of de gemeenschappelijke ruimten'
				title='Warmteverbruik'
				disabledText='Het warmteverbruik wordt niet meegenomen, en wordt door de huurders zelf betaald'
				summary={
					<ServiceMixSummary
						items={[
							{
								name: 'Sociale huur',
								year: heatingTotalYearCostsSocial(state),
								month: heatingTotalYearCostsSocial(state) / 12,
							},
							{
								name: 'Vrije sector',
								year: heatingTotalYearCostsFreeSector(state),
								month: heatingTotalYearCostsFreeSector(state) / 12,
							},
							{
								name: 'Gemeenschappelijk',
								year: heatingTotalYearCostsCommonArea(state),
								month: heatingTotalYearCostsCommonArea(state) / 12,
							},
						]}
						total={{
							year: totalHeating(state),
							month: totalHeating(state) / 12,
						}}
					/>
				}>
				<div style={curlyBraceStyle}>
					<div className="col-2">
						<div>
							<h4>Per woning</h4>
							<CostInput
								value={state.serviceReducer.heatingGJPerHousePerYear}
								unit='GJ/woning'
								helpText='m&#179; omrekenen naar GJ'
								onValueChange={setServiceValue('heatingGJPerHousePerYear', dispatch)} />
							<CostInput
								value={state.serviceReducer.heatingHousePricePerGJ}
								unit='per GJ'
								prefix='€ '
								onValueChange={setServiceValue('heatingHousePricePerGJ', dispatch)} />
						</div>
						<div>
							<h4>Gemeenschappelijke ruimte</h4>
							<CostInput
								value={state.serviceReducer.heatingGJPerCommonAreaPerM2}
								unit='GJ/m²'
								helpText='m&#179; omrekenen naar GJ'
								onValueChange={setServiceValue('heatingGJPerCommonAreaPerM2', dispatch)} />
							<CostInput
								value={state.serviceReducer.heatingCommonAreaPricePerGJ}
								unit='per GJ'
								prefix='€ '
								onValueChange={setServiceValue('heatingCommonAreaPricePerGJ', dispatch)} />
						</div>
					</div>
				</div>
			</ServiceCostComponent>

			<div className="hor-separator" />

			<ServiceCostComponent
				remark='Specificeer hier personeelskosten voor gemeenschappelijke ruimten zoals bijv. schoonmaak'
				isEnabled={state.serviceReducer.personnelEnabled}
				setEnabled={() => dispatch({ type: 'service/togglePersonnelEnabled' })}
				icon={<HousekeeperIcon />}
				title='Personeel'
				disabledText='Er is geen personeel gespecificeerd'
				summary={
					<ServiceMixSummary
						items={[
							{
								name: 'Huismeester',
								month: houseMasterTotalYearCosts(state) / 12,
								year: houseMasterTotalYearCosts(state),
							},
							{
								name: 'Administratie',
								month: administratorTotalYearCosts(state) / 12,
								year: administratorTotalYearCosts(state),
							},
							{
								name: 'Schoonmaak',
								month: cleaningCommonAreaTotalYearCosts(state) / 12,
								year: cleaningCommonAreaTotalYearCosts(state),
							},
						]}
						total={{
							year: totalPersonnelYearCosts(state),
							month: totalPersonnelYearCosts(state) / 12,
						}}
					/>
				}>
				<div style={curlyBraceStyle}>
					<div className="col-3">
						<div>
							<h4>Huismeester</h4>
							<CostInput
								value={state.serviceReducer.staffHouseMasterHoursPerWeek}
								unit='uur/week'
								onValueChange={setServiceValue('staffHouseMasterHoursPerWeek', dispatch)}
							/>
							<CostInput
								value={state.serviceReducer.staffHouseMasterPricePerHour}
								unit='per uur'
								prefix='€ '
								onValueChange={setServiceValue('staffHouseMasterPricePerHour', dispatch)}
							/>
						</div>

						<div>
							<h4>Administratie</h4>
							<CostInput
								value={state.serviceReducer.staffAdministratorHoursPerWeek}
								unit='uur/week'
								onValueChange={setServiceValue('staffAdministratorHoursPerWeek', dispatch)}
							/>
							<CostInput
								value={state.serviceReducer.staffAdministratorPricePerHour}
								unit='per uur'
								prefix='€ '
								onValueChange={setServiceValue('staffAdministratorPricePerHour', dispatch)}
							/>
						</div>

						<div>
							<h4>Schoonmaak gem. ruimte</h4>
							<CostInput
								value={state.serviceReducer.staffCleaningPerWeek}
								unit='uur/week'
								onValueChange={setServiceValue('staffCleaningPerWeek', dispatch)} />
							<CostInput
								value={state.serviceReducer.staffCleaningPricePerHour}
								unit='per uur'
								prefix='€ '
								onValueChange={setServiceValue('staffCleaningPricePerHour', dispatch)} />
						</div>
					</div>
				</div>
			</ServiceCostComponent>

			<div className="hor-separator" />

			<ServiceCostComponent
				isEnabled={state.serviceReducer.maintenanceEnabled}
				icon={<MaintenanceIcon />}
				remark='Specificeer hier onderhoudskosten zoals afkoop huurdersonderhoud of het tuinonderhoud'
				setEnabled={() => dispatch({ type: 'service/toggleMaintenanceEnabled' })}
				title='Onderhoud'
				disabledText='Er is geen onderhoud gespecificeerd'
				summary={
					<ServiceMixSummary
						items={[
							{ name: 'Afkoop onderh.', year: 0, month: 0 },
							{ name: 'Tuinonderhoud', year: 0, month: 0	},
						]}
						total={{ year: 0, month: 0 }} />
				}>
				<div style={curlyBraceStyle}>
					<div className="col-2">
						<div>
							<h4>Afkoop huurdersonderhoud</h4>
							<CostInput
								value={state.serviceReducer.maintenanceRedemptionOfTenantPerM2PerYear}
								prefix='€ '
								unit='per m² per jaar'
								onValueChange={setServiceValue('maintenanceRedemptionOfTenantPerM2PerYear', dispatch)} />
						</div>
						<div>
							<h4>Tuinonderhoud</h4>

							<CostInput
								value={state.serviceReducer.maintenanceGardenPerM2PerPlot}
								unit='per m² perceel'
								onValueChange={setServiceValue('maintenanceGardenPerM2PerPlot', dispatch)} />
						</div>
					</div>
				</div>
			</ServiceCostComponent>

			<div className="hor-separator" />

			<ServiceCostComponent
				isEnabled={state.serviceReducer.alarmEnabled}
				setEnabled={() => dispatch({ type: 'service/toggleAlarmEnabled' })}
				icon={<SirenIcon />}
				remark='Specificeer hier de kosten voor alarmering van de woningen'
				title='Alarmering'
				disabledText='Er is geen alarmering gespecificeerd'
				summary={
					<ServiceMixSummary
						items={[
							{
								name: 'Sociale huur',
								year: totalAlarmCostsSocial(state),
								month: totalAlarmCostsSocial(state) / 12,
							},
							{
								name: 'Vrije sector',
								year: totalAlarmCostsFreeSector(state),
								month: totalAlarmCostsFreeSector(state) / 12,
							},
						]}
						total={{
							year: totalAlarmCosts(state),
							month: totalAlarmCosts(state) / 12,
						}}
					/>
				}>
				<div style={curlyBraceStyle}>
					<div className="col-2">
						<div>
							<h4>Abonnement</h4>
							<CostInput
								value={state.serviceReducer.alarmSubscriptionsPricePerSubscription}
								prefix='€ '
								unit='per aansluiting'
								onValueChange={setServiceValue('alarmSubscriptionsPricePerSubscription', dispatch)} />
						</div>
						<div>
							<h4>Alarmopvolgingen</h4>
							<CostInput
								value={state.serviceReducer.alarmCallFollowUpsPerSubscriptionPerYear}
								unit=' / aansluiting / jaar'
								onValueChange={setServiceValue('alarmCallFollowUpsPerSubscriptionPerYear', dispatch)} />
							<CostInput
								value={state.serviceReducer.alarmCallFollowUpPricePerHour}
								prefix='€ '
								unit='per uur'
								onValueChange={setServiceValue('alarmCallFollowUpPricePerHour', dispatch)} />
							<CostInput
								value={state.serviceReducer.alarmCallFollowUpHours}
								unit='uur per oproep'
								onValueChange={setServiceValue('alarmCallFollowUpHours', dispatch)} />
						</div>
					</div>
				</div>
			</ServiceCostComponent>
		</Step>
	);
}
