import { createSelector } from 'reselect';

import { building } from './buildingReducer';
import { noHouses } from './housingReducer';

export function serviceReducer(state, action) {
	switch (action.type) {
	case 'service/setValue': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				[action.name]: action.value,
			},
		};
	}
	case 'service/toggleElectricityEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				electricityEnabled: !state.serviceReducer.electricityEnabled,
			},
		};
	}
	case 'service/toggleHeatingEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				heatingEnabled: !state.serviceReducer.heatingEnabled,
			},
		};
	}
	case 'service/togglePersonnelEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				personnelEnabled: !state.serviceReducer.personnelEnabled,
			},
		};
	}
	case 'service/toggleMaintenanceEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				maintenanceEnabled: !state.serviceReducer.maintenanceEnabled,
			},
		};
	}
	case 'service/toggleAlarmEnabled': {
		return {
			...state,
			serviceReducer: {
				...state.serviceReducer,
				alarmEnabled: !state.serviceReducer.alarmEnabled,
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const initialServiceReducer = {
	serviceReducer: {
		electricityEnabled: false,
		electricityKWhHousePerYear: 2600,
		electricityHousePricePerKWh: 0.22,

		electricityKWhCommonAreaPerM2: 10,
		electricityCommonAreaPricePerKWh: 0.22,

		heatingEnabled: false,
		heatingGJPerHousePerYear: 25,
		heatingHousePricePerGJ: 25,

		heatingGJPerCommonAreaPerM2: 0.25,
		heatingCommonAreaPricePerGJ: 25,

		personnelEnabled: false,
		staffHouseMasterHoursPerWeek: 8,
		staffHouseMasterPricePerHour: 30,

		staffAdministratorHoursPerWeek: 1,
		staffAdministratorPricePerHour: 35,

		staffCleaningPerWeek: 1,
		staffCleaningPricePerHour: 23,

		maintenanceEnabled: false,
		maintenanceRedemptionOfTenantPerM2PerYear: 0,
		maintenanceGardenPerM2PerPlot: 0,

		alarmEnabled: false,
		alarmSubscriptionsPricePerSubscription: 13,

		alarmCallFollowUpsPerSubscriptionPerYear: 2,
		alarmCallFollowUpPricePerHour: 28,
		alarmCallFollowUpHours: 1.5,

		inflation: 2,
	},
};

const service = (state) => state.serviceReducer;

export const houseMasterTotalYearCosts = createSelector(service, (service) => (!service.personnelEnabled ? 0 : service.staffHouseMasterHoursPerWeek * service.staffHouseMasterPricePerHour * 52)
);

export const administratorTotalYearCosts = createSelector(service, (service) => (!service.personnelEnabled ? 0 : service.staffAdministratorHoursPerWeek * service.staffAdministratorPricePerHour * 52)
);

export const cleaningCommonAreaTotalYearCosts = createSelector(service, (service) => (!service.personnelEnabled ? 0 : service.staffCleaningPerWeek * service.staffCleaningPricePerHour * 52)
);

export const totalPersonnelYearCosts = createSelector(
	houseMasterTotalYearCosts,
	administratorTotalYearCosts,
	cleaningCommonAreaTotalYearCosts,
	(houseMaster, administrator, cleaningCommonArea) => houseMaster + administrator + cleaningCommonArea
);

// TODO vraag Jasper waarom dit niet werd berekend.
// TODO MAINTENACE, moet dit. Dit is hetzelfde als de woningbouwvereniging extra servicepakketen
const maintenanceTotalYearCosts = createSelector(
	service,
	(service) => 0 // service.maintenanceRedemptionOfTenantPerM2PerYear * service.maintenanceGardenPerM2PerPlot * noHouses
);
export const houseMasterTotalYearCostsSocialHousing = createSelector(
	service,
	noHouses,
	houseMasterTotalYearCosts,
	(service, noHouses, houseMasterTotalYearCosts) => {
		return (noHouses.social / (noHouses.social + noHouses.freeSector)) * houseMasterTotalYearCosts;
	}
);

export const electricityTotalYearCostsSocial = createSelector(service, noHouses, (service, noHouses) => (!service.electricityEnabled
	? 0
	: service.electricityKWhHousePerYear * service.electricityHousePricePerKWh * noHouses.social)
);

export const heatingTotalYearCostsSocial = createSelector(service, noHouses, (service, noHouses) => (!service.electricityEnabled
	? 0
	: service.heatingGJPerHousePerYear * service.heatingHousePricePerGJ * noHouses.social)
);

export const alarmSubscriptionsCostsSocial = createSelector(service, noHouses, (service, noHouses) => (!service.alarmEnabled ? 0 : service.alarmSubscriptionsPricePerSubscription * noHouses.social * 12)
);

export const alarmFollowUpsCostsSocial = createSelector(service, noHouses, (service, noHouses) => (!service.alarmEnabled
	? 0
	: service.alarmCallFollowUpPricePerHour *
		  service.alarmCallFollowUpHours *
		  service.alarmCallFollowUpsPerSubscriptionPerYear *
		  noHouses.social)
);

export const totalAlarmCostsSocial = createSelector(
	alarmSubscriptionsCostsSocial,
	alarmFollowUpsCostsSocial,
	(alarmSubscriptionsCostsSocial, alarmFollowUpsCostsSocial) => alarmSubscriptionsCostsSocial + alarmFollowUpsCostsSocial
);

export const serviceCostsSocialHousingWithoutHouseMaster = createSelector(
	electricityTotalYearCostsSocial,
	heatingTotalYearCostsSocial,
	totalAlarmCostsSocial,
	(electricityTotalYearCostsSocial, heatingTotalYearCostsSocial, totalAlarmCostsSocial) => {
		// TODO vraag Jasper zie Gitlab issue
		// const staffAdministrator =
		// 	(noHouses.social / (noHouses.social + noHouses.freeSector)) * administratorTotalYearCosts;
		// const staffCleaning = (noHouses.social / (noHouses.social + noHouses.freeSector)) * cleaningTotalYearCosts;
		// const staffCosts = staffHouseMaster + staffAdministrator + staffCleaning;

		return electricityTotalYearCostsSocial + heatingTotalYearCostsSocial + totalAlarmCostsSocial;
	}
);

export const serviceCostsSocialHousing = createSelector(
	houseMasterTotalYearCostsSocialHousing,
	serviceCostsSocialHousingWithoutHouseMaster,
	(houseMasterTotalYearCostsSocialHousing, serviceCostsSocialHousingWithoutHouseMaster) => {
		return houseMasterTotalYearCostsSocialHousing + serviceCostsSocialHousingWithoutHouseMaster;
	}
);

export const houseMasterTotalYearCostsFreeSector = createSelector(
	service,
	noHouses,
	houseMasterTotalYearCosts,
	(service, noHouses, houseMasterTotalYearCosts) => {
		return !service.personnelEnabled
			? 0
			: (noHouses.freeSector / (noHouses.social + noHouses.freeSector)) * houseMasterTotalYearCosts;
	}
);

export const electricityTotalYearCostsFreeSector = createSelector(service, noHouses, (service, noHouses) => (!service.electricityEnabled
	? 0
	: service.electricityKWhHousePerYear * service.electricityHousePricePerKWh * noHouses.freeSector)
);

export const heatingTotalYearCostsFreeSector = createSelector(service, noHouses, (service, noHouses) => (!service.heatingEnabled
	? 0
	: service.heatingGJPerHousePerYear * service.heatingHousePricePerGJ * noHouses.freeSector)
);

export const alarmSubscriptionsCostsFreeSector = createSelector(service, noHouses, (service, noHouses) => (!service.alarmEnabled ? 0 : service.alarmSubscriptionsPricePerSubscription * noHouses.freeSector * 12)
);

export const alarmCallFollowUpsCostsFreeSector = createSelector(service, noHouses, (service, noHouses) => (!service.alarmEnabled
	? 0
	: service.alarmCallFollowUpPricePerHour *
		  service.alarmCallFollowUpHours *
		  service.alarmCallFollowUpsPerSubscriptionPerYear *
		  noHouses.freeSector)
);

export const totalAlarmCostsFreeSector = createSelector(
	alarmSubscriptionsCostsFreeSector,
	alarmCallFollowUpsCostsFreeSector,
	(alarmSubscriptionsCostsFreeSector, alarmCallFollowUpsCostsFreeSector) => {
		return alarmSubscriptionsCostsFreeSector + alarmCallFollowUpsCostsFreeSector;
	}
);

export const totalAlarmCosts = createSelector(
	totalAlarmCostsSocial,
	totalAlarmCostsFreeSector,
	(totalAlarmCostsSocial, totalAlarmCostsFreeSector) => {
		return totalAlarmCostsSocial + totalAlarmCostsFreeSector;
	}
);

export const serviceCostsFreeSectorWithoutHouseMaster = createSelector(
	electricityTotalYearCostsFreeSector,
	heatingTotalYearCostsFreeSector,
	totalAlarmCostsFreeSector,
	(electricityTotalYearCostsFreeSector, heatingTotalYearCostsFreeSector, totalAlarmCosts) => {
		return electricityTotalYearCostsFreeSector + heatingTotalYearCostsFreeSector + totalAlarmCosts;
	}
);

export const serviceCostsFreeSector = createSelector(
	serviceCostsFreeSectorWithoutHouseMaster,
	houseMasterTotalYearCostsFreeSector,
	(serviceCostsFreeSectorWithoutHouseMaster, houseMasterTotalYearCostsFreeSector) => {
		// TODO vraag Jasper zie Gitlab issue
		// const staffAdministrator =
		// 	(noHouses.freeSector / (noHouses.social + noHouses.freeSector)) * administratorTotalYearCosts;
		// const staffCleaning = (noHouses.freeSector / (noHouses.social + noHouses.freeSector)) * cleaningTotalYearCosts;
		// const staffCosts = staffHouseMaster + staffAdministrator + staffCleaning;

		return serviceCostsFreeSectorWithoutHouseMaster + houseMasterTotalYearCostsFreeSector;
	}
);

export const electricityTotalYearCostsCommonArea = createSelector(service, building, (service, building) => (!service.electricityEnabled
	? 0
	: service.electricityKWhCommonAreaPerM2 * service.electricityCommonAreaPricePerKWh * building.commonAreaVvo)
);

export const heatingTotalYearCostsCommonArea = createSelector(
	service,
	building,
	(service, building) => (!service.heatingEnabled
		? 0
		: service.heatingGJPerCommonAreaPerM2 * service.heatingCommonAreaPricePerGJ * building.commonAreaBvo) // why bvo?
);

export const serviceCostsCommonAreaWithoutHouseMaster = createSelector(
	electricityTotalYearCostsCommonArea,
	heatingTotalYearCostsCommonArea,
	cleaningCommonAreaTotalYearCosts,
	(electricityTotalYearCostsCommonArea, heatingTotalYearCostsCommonArea, cleaningTotalYearCosts) => {
		return electricityTotalYearCostsCommonArea + heatingTotalYearCostsCommonArea + cleaningTotalYearCosts;
	}
);

export const serviceCostsCommonArea = createSelector(
	houseMasterTotalYearCosts,
	houseMasterTotalYearCostsSocialHousing,
	houseMasterTotalYearCostsFreeSector,
	serviceCostsCommonAreaWithoutHouseMaster,
	(
		houseMasterTotalYearCosts,
		houseMasterTotalYearCostsSocialHousing,
		houseMasterTotalYearCostsFreeSector,
		serviceCostsCommonAreaWithoutHouseMaster
	) => {
		const staffHouseMaster =
			houseMasterTotalYearCosts - houseMasterTotalYearCostsSocialHousing - houseMasterTotalYearCostsFreeSector;

		return serviceCostsCommonAreaWithoutHouseMaster + staffHouseMaster;
	}
);

export const totalServiceCostsReal = createSelector(
	serviceCostsSocialHousing,
	serviceCostsFreeSector,
	serviceCostsCommonArea,
	administratorTotalYearCosts,
	(serviceCostsSocialHousing, serviceCostsFreeSector, serviceCostsCommonArea, administratorTotalYearCosts) => {
		return (
			serviceCostsSocialHousing + serviceCostsFreeSector + serviceCostsCommonArea + administratorTotalYearCosts
		);
	}
);

export const totalElectricity = createSelector(
	electricityTotalYearCostsSocial,
	electricityTotalYearCostsFreeSector,
	electricityTotalYearCostsCommonArea,
	(electricityTotalYearCostsSocial, electricityTotalYearCostsFreeSector, electicityTotalYearCostsCommonArea) => {
		return (
			electricityTotalYearCostsSocial + electricityTotalYearCostsFreeSector + electicityTotalYearCostsCommonArea
		);
	}
);

export const totalHeating = createSelector(
	heatingTotalYearCostsSocial,
	heatingTotalYearCostsFreeSector,
	heatingTotalYearCostsCommonArea,
	(heatingTotalYearCostsSocial, heatingTotalYearCostsFreeSector, heatingTotalYearCostsCommonArea) => {
		return heatingTotalYearCostsSocial + heatingTotalYearCostsFreeSector + heatingTotalYearCostsCommonArea;
	}
);

export const totalAlarmSubscriptionsCosts = createSelector(
	alarmSubscriptionsCostsSocial,
	alarmSubscriptionsCostsFreeSector,
	// alarmSubscriptionsCostsCommonArea,
	(
		alarmSubscriptionsCostsSocial,
		alarmSubscriptionsCostsFreeSector
		// alarmSubscriptionsCostsCommonArea
	) => {
		return alarmSubscriptionsCostsSocial + alarmSubscriptionsCostsFreeSector;
	}
);

export const totalAlarmFollowUpsCosts = createSelector(
	alarmFollowUpsCostsSocial,
	alarmCallFollowUpsCostsFreeSector,
	// alarmCallFollowUpsCostsCommonArea,
	(
		alarmCallFollowUpsCostsSocial,
		alarmCallFollowUpsCostsFreeSector
		// alarmCallFollowUpsCostsCommonArea
	) => {
		return alarmCallFollowUpsCostsSocial + alarmCallFollowUpsCostsFreeSector;
	}
);

export const totalServiceCostsHuurcommissie = createSelector(
	totalElectricity,
	totalHeating,
	houseMasterTotalYearCosts,
	cleaningCommonAreaTotalYearCosts,
	maintenanceTotalYearCosts,
	totalAlarmSubscriptionsCosts,
	totalAlarmFollowUpsCosts,
	(
		totalElectricity,
		totalHeating,
		houseMasterTotalYearCosts,
		cleaningTotalYearCosts,
		maintenanceTotalYearCosts,
		totalAlarmSubscriptionsCosts,
		totalAlarmFollowUpsCosts
	) => {
		const tussenstand =
			totalElectricity +
			totalHeating +
			houseMasterTotalYearCosts * 0.7 +
			cleaningTotalYearCosts +
			maintenanceTotalYearCosts +
			totalAlarmSubscriptionsCosts +
			totalAlarmFollowUpsCosts;

		const adminCostsVolgensHuurcommissie = tussenstand * 0.05;
		const derving = (tussenstand + adminCostsVolgensHuurcommissie) * 0.02;
		const andereMagie = (tussenstand + adminCostsVolgensHuurcommissie + derving) * 0.02;
		return tussenstand + adminCostsVolgensHuurcommissie + derving - andereMagie;
	}
);

export const totalServiceCostsVsHuurcommissieRatio = createSelector(
	totalServiceCostsHuurcommissie,
	totalServiceCostsReal,
	(totalServiceCostsHuurcommissie, totalServiceCostsReal) => {
		const result = totalServiceCostsHuurcommissie / totalServiceCostsReal;
		return Number.isNaN(result) ? 0 : result;
	}
);
