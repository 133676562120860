import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
	GridComponent,
	LegendComponent,
	LegendScrollComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	VisualMapComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { useContext } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { AutoSizer } from 'react-virtualized';

import {
	capitalRequired,
	financingGap,
	giftsAndSubsidies,
	totalMortgage,
	totalSoftLoan,
} from '../../../reducers/financingReducer';
import { totalBuyCostsSocialAndFreeSectorOnly } from '../../../reducers/investingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { CalculationTableRow } from '../../lib/calctable/CalculationTableRow';
import { CalculationTableRowTerm, Sign } from '../../lib/calctable/CalculationTableRowTerm';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { Step } from '../Step';
import { VALUE_STEP } from '../s9-value/ValuationStep';

export const FINANCE_STEP = 'finance';
echarts.use([
	PieChart,
	TitleComponent,
	TooltipComponent,
	GridComponent,
	ToolboxComponent,
	LegendComponent,
	LegendScrollComponent,
	VisualMapComponent,
	SVGRenderer,
]);

export function FinancingStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	return (
		<Step
			name='Financiering'
			id={FINANCE_STEP}
			description='Bepaal hoe het project gefinancierd gaat worden'
			icon='bi:cash-coin'
			showContent={state.stepReducer.step === FINANCE_STEP}
			readyForNextStep={true}
			nextStep={VALUE_STEP}
			thisStep={FINANCE_STEP}
			summaryComponent={
				<div className="col-2">
					<div>
						<div style={{ borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Kapitaalbehoefte</div>
								<div>
									<FormattedNumber
										value={totalBuyCostsSocialAndFreeSectorOnly(state)}
										prefix='€ '
										className='bold-value'
										decimalScale={0}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Giften en subsidies</div>
								<div>
									<FormattedNumber
										className='bold-value'
										value={giftsAndSubsidies(state)}
										prefix='€ '
										decimalScale={0}
									/>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 'var(--u-4)' }}>
								<div>Hypotheek</div>
								<div style={{ textAlign: 'right' }}>
									<FormattedNumber
										className='bold-value'
										value={totalMortgage(state)}
										prefix='€ '
										decimalScale={0} />
									<br />
									<small>
										<FormattedNumber
											value={state.financingReducer.mortgage.interestRate}
											decimalScale={2}
											suffix='%' />{' '}
										rente voor {state.financingReducer.mortgage.years} jaar
									</small>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 'var(--u-4)' }}>
								<div>Achtergestelde lening</div>
								<div style={{ textAlign: 'right' }}>
									<FormattedNumber
										className='bold-value'
										value={totalSoftLoan(state)}
										prefix='€ '
										decimalScale={0}
									/>
									<br />
									<small>
										<FormattedNumber
											value={state.financingReducer.softLoans.interestRate}
											decimalScale={2}
											suffix='%'
										/>{' '}
										rente voor {state.financingReducer.softLoans.years} jaar
									</small>
								</div>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>Nog te financieren</div>
							<div>
								<FormattedNumber
									className='bold-value'
									value={financingGap(state)}
									prefix='€ '
									decimalScale={0} />
							</div>
						</div>
					</div>
				</div>
			}>
			<div>
				<CalculationTableRow title='Giften en subsidies'>
					<CalculationTableRowTerm
						value={capitalRequired(state)}
						unit='kapitaalbehoefte'
						decimals={0}
						prefix='€ '
						wide={true} />
					<CalculationTableRowTerm
						sign='-'
						value={state.financingReducer.giftsAndSubsidies}
						id='giftsAndSubsidies'
						unit='te ontvangen'
						decimals={0}
						prefix='€ '
						wide={true}
						editable={true}
						onValueChange={({ floatValue }, { event }) => {
							if (event) {
								dispatch({
									type: 'financing/setValue',
									name: 'giftsAndSubsidies',
									value: floatValue,
								});
							}
						}} />
					<CalculationTableRowTerm
						sign='='
						value={capitalRequired(state) - (state.financingReducer.giftsAndSubsidies ?? 0)}
						unit='over'
						wide={true}
						prefix='€ '
						decimals={0}
						editable={false} />
				</CalculationTableRow>

				<div className="hor-separator" />

				<CalculationTableRow title='Hypotheek'>
					<CalculationTableRowTerm
						value={capitalRequired(state)}
						unit='kapitaalbehoefte'
						decimals={0}
						prefix='€ '
						wide={true} />
					<CalculationTableRowTerm
						sign='*'
						value={state.financingReducer.mortgage.percentage}
						unit='te lenen'
						decimals={0}
						suffix='%'
						wide={true}
						editable={true}
						onValueChange={({ floatValue }, { event }) => {
							if (event) {
								dispatch({
									type: 'financing/setMortgageValue',
									name: 'percentage',
									value: floatValue,
								});
							}
						}} />
					<CalculationTableRowTerm
						sign="="
						value={totalMortgage(state)}
						id='mortgage'
						unit='te lenen'
						wide={true}
						prefix='€ '
						decimals={0}
						editable={false} />
				</CalculationTableRow>

				<div className="col-3" style={{ gridTemplateColumns: '2fr 1fr', paddingTop: 'var(--u-16)', paddingBottom: 'var(--u-16)' }}>
					<div className="col-3">
						<div>
							<h4>Rente</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.mortgage.interestRate}
								decimals={2}
								textLeft={true}
								suffix='%'
								editable={true}
								wide={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setMortgageValue',
											name: 'interestRate',
											value: floatValue,
										});
									}
								}} />
						</div>

						<div>
							<h4>Looptijd</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.mortgage.years}
								decimals={0}
								unit='jaar'
								wide={true}
								editable={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setMortgageValue',
											name: 'years',
											value: floatValue,
										});
									}
								}} />
						</div>

						<div>
							<h4>Totaal aflossen</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.mortgage.redemptionPercentage}
								decimals={0}
								suffix='%'
								textLeft={true}
								wide={true}
								editable={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setMortgageValue',
											name: 'redemptionPercentage',
											value: floatValue,
										});
									}
								}} />
						</div>
					</div>
				</div>

				<div className='hor-separator' />

				<CalculationTableRow title='Achtergestelde leningen' helpText='Dit zijn &apos;zachte&apos; leningen en crowdfundingleningen die achtergesteld zijn aan de hypotheek, dit wil zeggen dat de uitleners bij een faillissement hun geld pas terugkrijgen als de hypotheekverstrekkers afbetaald zijn.'>
					<CalculationTableRowTerm
						value={capitalRequired(state)}
						unit='kapitaalbehoefte'
						decimals={0}
						prefix='€ '
						wide={true}
					/>
					<CalculationTableRowTerm
						sign='*'
						value={state.financingReducer.softLoans.percentage}
						unit='te lenen'
						decimals={0}
						suffix='%'
						wide={true}
						editable={true}
						onValueChange={({ floatValue }, { event }) => {
							if (event) {
								dispatch({
									type: 'financing/setSoftLoanValue',
									name: 'percentage',
									value: floatValue,
								});
							}
						}}
					/>
					<CalculationTableRowTerm
						sign="="
						value={totalSoftLoan(state)}
						unit='te lenen'
						id='softloan'
						wide={true}
						prefix='€ '
						decimals={0}
						editable={false}
					/>
				</CalculationTableRow>

				<div className="col-3" style={{ gridTemplateColumns: '2fr 1fr', paddingTop: 'var(--u-16)', paddingBottom: 'var(--u-16)' }}>
					<div className="col-3">
						<div>
							<h4>Rente</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.softLoans.interestRate}
								decimals={2}
								textLeft={true}
								suffix='%'
								editable={true}
								wide={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setSoftLoanValue',
											name: 'interestRate',
											value: floatValue,
										});
									}
								}} />
						</div>

						<div>
							<h4>Looptijd</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.softLoans.years}
								decimals={0}
								unit='jaar'
								editable={true}
								wide={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setSoftLoanValue',
											name: 'years',
											value: floatValue,
										});
									}
								}} />
						</div>

						<div>
							<h4>Totaal aflossen</h4>
							<CalculationTableRowTerm
								value={state.financingReducer.softLoans.redemptionPercentage}
								decimals={0}
								suffix='%'
								textLeft={true}
								wide={true}
								editable={true}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'financing/setSoftLoanValue',
											name: 'redemptionPercentage',
											value: floatValue,
										});
									}
								}} />
						</div>
					</div>
				</div>

				<div className='hor-separator' />

				<div className='col-2'>
					<div style={{ minHeight: '180px' }}>
						<AutoSizer>
							{({ height, width }) => (
								<ReactEChartsCore
									echarts={echarts}
									option={{
										title: {
											text: 'Verdeling financiën',
											left: 'center',
											top: 0,
											textStyle: {
												color: '#999',
												fontWeight: 'normal',
												fontSize: 14,
											},
										},
										tooltip: {
											trigger: 'item',
											formatter: ({ name, value, percent }) => {
												return renderToStaticMarkup(
													<span>
														{name}:{' '}
														<span className='font-bold'>
															{parseFloat(value).toLocaleString('nl-NL', {
																style: 'currency',
																currency: 'EUR',
																maximumFractionDigits: 0,
															})}
														</span>{' '}
														({percent}%)
													</span>
												);
											},
											extraCssText: 'z-index: 9999;',
										},
										series: [
											{
												type: 'pie',
												radius: [ 20, 50 ],
												top: 'center',
												left: 0,
												itemStyle: {
													borderColor: '#fff',
													borderWidth: 1,
												},

												label: {
													position: 'outside',
													alignTo: 'edge',
													formatter: '{b}',
													lineHeight: 0,
													distanceToLabelLine: 0,
													bleedMargin: 0,
													edgeDistance: 30,
													width: 200,
												},

												labelLine: {
													length: 20,
													length2: 0,
													showAbove: true,
												},
												data: [
													{
														name: 'Giften & subsidies',
														value: giftsAndSubsidies(state),
														itemStyle: {
															color: '#4f46e5',
														},
													},
													{
														name: 'Hypotheek',
														value: totalMortgage(state),
														itemStyle: {
															color: '#6366F1',
														},
													},
													{
														name: 'Achtergestelde lening',
														value: totalSoftLoan(state),
														itemStyle: {
															color: '#818CF8',
														},
													},
													{
														name: 'Nog te financieren',
														value: financingGap(state),
														itemStyle: {
															color: '#C7D2FE',
														},
													},
												],
											},
										],
									}}
									style={{ height, width }}
								/>
							)}
						</AutoSizer>
					</div>
					<div>
						<div style={{ display: 'inline-block' }}>
							<div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
								<CalculationTableRowTerm
									wide={true}
									prefix='€ '
									decimals={0}
									value={capitalRequired(state)} />
								<div style={{ paddingRight: 'var(--u-8)' }}>
									<div style={{ fontWeight: 500, color: 'var(--col-grey-700)', fontSize: 'var(--fs-11)' }}>Kapitaalbehoefte</div>
								</div>
							</div>
							<div className='mt-2'>
								<CalculationTableRowTerm
									wide={true}
									prefix='€ '
									decimals={0}
									value={state.financingReducer.giftsAndSubsidies}
									reference={{ text: 'Giften & Subsidies', id: 'giftsAndSubsidies', step: FINANCE_STEP }} />
							</div>
							<div>
								<CalculationTableRowTerm
									wide={true}
									prefix='€ '
									decimals={0}
									value={totalMortgage(state)}
									reference={{ text: 'Hypotheek', id: 'mortgage', step: FINANCE_STEP }} />
							</div>
							<div className=''>
								<CalculationTableRowTerm
									wide={true}
									prefix='€ '
									decimals={0}
									value={totalSoftLoan(state)}
									reference={{
										text: 'Achtergestelde lening',
										id: 'softloan',
										step: FINANCE_STEP,
									}} />
							</div>
							<div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: 'var(--u-16)' }}>
								{/* <div style={{ position: 'relative', left: '-24px' }}>*/}
								<div>
									<Sign sign='-' />
								</div>
								{/* </div>*/}
								<div style={{ flex: 1, paddingBottom: '3.5px' }}>
									<div style={{ borderTop: '2px solid var(--col-grey-300)' }} />
								</div>
							</div>
							<div>
								<CalculationTableRowTerm
									wide={true}
									value={financingGap(state)}
									unit='nog te financieren'
									prefix='€ '
									decimals={0} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Step>
	);
}
