import { createSelector } from 'reselect';

import { housing } from './housingReducer';
import { totalBuyCostsSocialAndFreeSectorOnly } from './investingReducer';
import { value } from './valueReducer';

export function financingReducer(state, action) {
	switch (action.type) {
	case 'financing/setValue': {
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				[action.name]: action.value,
			},
		};
	}
	case 'financing/setBuildingLoanValue': {
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				buildingLoan: {
					...state.financingReducer.buildingLoan,
					[action.name]: action.value,
				},
			},
		};
	}
	case 'financing/setMortgageValue': {
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				mortgage: {
					...state.financingReducer.mortgage,
					[action.name]: action.value,
				},
			},
		};
	}
	case 'financing/setSoftLoanValue': {
		return {
			...state,
			financingReducer: {
				...state.financingReducer,
				softLoans: {
					...state.financingReducer.softLoans,
					[action.name]: action.value,
				},
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const FinancingType = {
	annuity: 'annuity',
	linear: 'linear',
};

export const initialFinancingReducerState = {
	financingReducer: {
		giftsAndSubsidies: 0,
		buildingLoan: {
			gracePeriod: 2,
		},
		mortgage: {
			type: FinancingType.annuity,
			percentage: 60, // of rental houses
			redemptionPercentage: 100,
			interestRate: 2, // in %
			refinanceAfter: 10, // years
			interestRateAfterRefinance: 2, // in %
			years: 30, // years
			// periods: 30,
		},
		softLoans: {
			type: FinancingType.linear,
			percentage: 30,
			redemptionPercentage: 100,
			years: 30,
			// periods: 30,
			interestRate: 4, // in %
		},
	},
};

export const financing = (state) => state.financingReducer;

export const capitalRequired = createSelector(
	totalBuyCostsSocialAndFreeSectorOnly,
	totalBuyCostsSocialAndFreeSectorOnly => totalBuyCostsSocialAndFreeSectorOnly,
);

export const giftsAndSubsidies = createSelector(
	financing,
	financing => financing.giftsAndSubsidies,
);


export const totalMortgage = createSelector(
	financing,
	capitalRequired,
	(financing, capitalRequired) => {
		return (financing.mortgage.percentage / 100) * capitalRequired;
	}
);

export const totalSoftLoan = createSelector(
	financing,
	capitalRequired,
	(financing, capitalRequired) => {
		return (financing.softLoans.percentage / 100) * capitalRequired;
	}
);

export const financingGap = createSelector(
	giftsAndSubsidies,
	totalMortgage,
	totalSoftLoan,
	capitalRequired,
	(giftsAndSubsidies, totalMortgage, totalSoftLoan, capitalRequired) => {
		return capitalRequired - totalMortgage - totalSoftLoan - giftsAndSubsidies;
	}
);

// const inbreng = totalToFinance - mortgageTotal - softLoanTotal;
export const firstYearCapitalRequirement = createSelector(
	giftsAndSubsidies,
	totalMortgage,
	totalSoftLoan,
	financingGap,
	(giftsAndSubsidies, totalMortgage, totalSoftLoan, financingGap) => {
		return giftsAndSubsidies + financingGap + totalMortgage + totalSoftLoan;
	}
);

export const loanToValue = createSelector(totalMortgage, value, housing, (totalMortgage, value, housing) => {
	return (
		totalMortgage /
		(value.VONSocialPerM2 * housing.noHouses.social * housing.areaVvo.social +
			value.VONFreeSectorPerM2 * housing.noHouses.freeSector * housing.areaVvo.freeSector +
			value.VONBuyPerM2 * housing.noHouses.buy * housing.areaVvo.buy)
	);
});
