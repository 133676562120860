import { useContext, useMemo } from 'react';

import { buildingAreaTotalBvo } from '../../../reducers/buildingReducer';
import { totalHousesAreaBvo, totalNoOfHouses, vormRatio } from '../../../reducers/housingReducer';
import { BouwCalculatorReducerContext, bvoToVvoMultiplier } from '../../../reducers/StepsStoreProvider';
import { Alert, AlertType } from '../../lib/Alert';
import { CalculationTableRowTerm } from '../../lib/calctable/CalculationTableRowTerm';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { hasValidValues, Step } from '../Step';
import SurfaceComparison from '../../lib/visual/SurfaceComparison';
import { BUILDING_STEP } from '../s3-building/BuildingStep';
import ShowConditional from '../../lib/ShowConditional';

export const UNIT_STEP = 'unit';

export function UnitLayoutStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	const { areaBvo, areaVvo, noHouses } = state.housingReducer;

	const totalMaxArea = [
		noHouses.social == 0 ? null : state.housingReducer.areaBvo.social,
		noHouses.freeSector == 0 ? null : state.housingReducer.areaBvo.freeSector,
		noHouses.buy == 0 ? null : state.housingReducer.areaBvo.buy,
		noHouses.social == 0 ? null : state.housingReducer.areaVvo.social,
		noHouses.freeSector == 0 ? null : state.housingReducer.areaVvo.freeSector,
		noHouses.buy == 0 ? null : state.housingReducer.areaVvo.buy,
	].filter(x => x != null).reduce((max, e) => (e > max ? e : max), 1);

	return (
		<Step
			name='Woninglayout'
			id={UNIT_STEP}
			description='Stel in hoe de woningen er uit komen te zien'
			icon='clarity:building-solid'
			showContent={state.stepReducer.step === UNIT_STEP}
			nextStepValidations={[
				{ valid: areaBvo.social != null && areaBvo.social > 0, error: 'Brutovloeroppervlak sociale huur moet nog ingevuld worden', type: 'social' },
				{ valid: areaBvo.freeSector != null && areaBvo.freeSector > 0, error: 'Brutovloeroppervlak van vrije sector huur moet nog ingevuld worden', type: 'freesector' },
				{ valid: areaBvo.buy != null && areaBvo.buy > 0, error: 'Brutovloeroppervlak koopwoningen moet nog ingevuld worden', type: 'buy' },
				{ valid: areaVvo.social != null && areaVvo.social > 0, error: 'Woonoppervlak sociale huur moet nog ingevuld worden', type: 'social' },
				{ valid: areaVvo.freeSector != null && areaVvo.freeSector > 0, error: 'Woonoppervlak van vrije sector huur moet nog ingevuld worden', type: 'freesector' },
				{ valid: areaVvo.buy != null && areaVvo.buy > 0, error: 'Woonoppervlak koopwoningen moet nog ingevuld worden', type: 'buy' },
			].filter(v => v.type != 'social' || noHouses.social > 0).filter(v => v.type != 'freesector' || noHouses.freeSector > 0).filter(v => v.type != 'buy' || noHouses.buy > 0)}
			thisStep={UNIT_STEP}
			nextStep={BUILDING_STEP}
			summaryInError={
				hasValidValues([ buildingAreaTotalBvo(state) ]) ||
				totalNoOfHouses(state) == null ||
				isNaN(totalNoOfHouses(state))
			}
			summaryComponent={
				<>
					<div className='col-2'>
						<div>
							<div style={{ borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
								<div>Brutovloeroppervlak</div>
							</div>
							<ShowConditional condition={state.housingReducer.noHouses.social > 0}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>Sociale huur</div>
									<div className='bold-value'>
										<FormattedNumber value={state.housingReducer.areaBvo.social} decimalScale={0} /> m²
									</div>
								</div>
							</ShowConditional>
							<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>Vrije sector</div>
									<div className='bold-value'>
										<FormattedNumber value={state.housingReducer.areaBvo.freeSector} decimalScale={0} /> m²
									</div>
								</div>
							</ShowConditional>
							<ShowConditional condition={state.housingReducer.noHouses.buy > 0}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>Koop</div>
									<div className='bold-value'>
										<FormattedNumber value={state.housingReducer.areaBvo.buy} decimalScale={0} /> m²
									</div>
								</div>
							</ShowConditional>
						</div>
						<div className='col-span-12 md:col-span-6'>
							<div style={{ borderBottom: '1px solid var(--col-green-400)', marginBottom: 'var(--u-8)' }}>
								<div>Woonoppervlak</div>
							</div>
							<ShowConditional condition={state.housingReducer.noHouses.social > 0}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>Sociale huur</div>
									<div className='bold-value'>
										<FormattedNumber value={state.housingReducer.areaVvo.social} decimalScale={0} /> m²
									</div>
								</div>
							</ShowConditional>
							<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>Vrije sector</div>
									<div className='bold-value'>
										<FormattedNumber value={state.housingReducer.areaVvo.freeSector} decimalScale={0} /> m²
									</div>
								</div>
							</ShowConditional>
							<ShowConditional condition={state.housingReducer.noHouses.buy > 0}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>Koop</div>
									<div className='bold-value'>
										<FormattedNumber value={state.housingReducer.areaVvo.buy} decimalScale={0} /> m²
									</div>
								</div>
							</ShowConditional>
						</div>

					</div>
					<div>
						<br />
						<span>
							<span className='bold-value'>
								<FormattedNumber value={totalHousesAreaBvo(state)} decimalScale={0} /> m²
							</span>{' '}
							brutovloeroppervlakte van woonunits
						</span>
					</div>
				</>
			}>
			<div>
				<h3 className='header'>Brutovloeroppervlakte</h3>

				<div className='col-2'>
					<div>
						<ShowConditional condition={noHouses.social > 0} style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.housingReducer.areaBvo.social}
								unit='m² bvo sociale huur'
								decimals={0}
								editable={true}
								min={1}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'housing/areaBvo',
											name: 'social',
											value: parseInt(event.target.value, 10),
										});
									}
								}}
							/>
						</ShowConditional>

						<ShowConditional condition={noHouses.freeSector > 0} style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.housingReducer.areaBvo.freeSector}
								unit='m² bvo vrije sector'
								decimals={0}
								editable={true}
								min={1}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'housing/areaBvo',
											name: 'freeSector',
											value: parseInt(event.target.value, 10),
										});
									}
								}}
							/>
						</ShowConditional>

						<ShowConditional condition={noHouses.buy > 0} style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.housingReducer.areaBvo.buy}
								unit='m² bvo koopwoningen'
								decimals={0}
								editable={true}
								min={1}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'housing/areaBvo',
											name: 'buy',
											value: parseInt(event.target.value, 10),
										});
									}
								}}
							/>
						</ShowConditional>

						<p className='remark mt-4'>
							Dit is hoe veel ruimte je nodig hebt om de woning te bouwen, inclusief muren en andere overhead, exclusief gedeelde ruimtes. Vul het gewogen gemiddelde in als de woningen van elkaar verschillen.
						</p>
					</div>

					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<div style={{ maxWidth: '300px', flex: 1 }}>
							<SurfaceComparison
								surfaces={[
									noHouses.social == 0 ? null : { area: state.housingReducer.areaBvo.social, color: '#C7D2FE' },
									noHouses.freeSector == 0 ? null : { area: state.housingReducer.areaBvo.freeSector, color: '#818CF8' },
									noHouses.buy == 0 ? null : { area: state.housingReducer.areaBvo.buy, color: '#4F46E5' },
								].filter(x => x != null)}
								maxArea={totalMaxArea}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="hor-separator" />

			<div>
				<h3 className='header'>Gebruiksoppervlak</h3>

				<div className="col-2">
					<div>
						<ShowConditional condition={noHouses.social > 0} style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.housingReducer.areaVvo.social}
								unit='m² gebruiksoppervlak sociale huur'
								decimals={0}
								editable={true}
								min={1}
								initialValue={useMemo(
									() => state.housingReducer.areaBvo.social * bvoToVvoMultiplier,
									[ state.housingReducer.areaBvo.social ]
								)}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'housing/areaVvo',
											name: 'social',
											value: floatValue,
										});
									}
								}}
								warnMax={useMemo(
									() => state.housingReducer.areaBvo.social * (bvoToVvoMultiplier + 0.07),
									[ state.housingReducer.areaBvo.social ]
								)}
								warnMaxText='Het verhuurbaar vloeroppervlak komt erg dicht in de buurt of gaat over het bruto vloeroppervlak'
							/>
						</ShowConditional>

						<ShowConditional condition={noHouses.freeSector > 0} style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.housingReducer.areaVvo.freeSector}
								unit='m² gebruiksoppervlak vrije sector'
								decimals={0}
								editable={true}
								min={1}
								initialValue={useMemo(
									() => state.housingReducer.areaBvo.freeSector * bvoToVvoMultiplier,
									[ state.housingReducer.areaBvo.freeSector ]
								)}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'housing/areaVvo',
											name: 'freeSector',
											value: floatValue,
										});
									}
								}}
								warnMax={useMemo(
									() => state.housingReducer.areaBvo.freeSector * (bvoToVvoMultiplier + 0.07),
									[ state.housingReducer.areaBvo.freeSector ]
								)}
								warnMaxText='Het verhuurbaar vloeroppervlak komt erg dicht in de buurt of gaat over het bruto vloeroppervlak'
							/>
						</ShowConditional>

						<ShowConditional condition={noHouses.buy > 0} style={{ paddingBottom: '4px' }}>
							<CalculationTableRowTerm
								value={state.housingReducer.areaVvo.buy}
								unit='m² gebruiksoppervlak koopwoningen'
								decimals={0}
								editable={true}
								min={1}
								initialValue={useMemo(
									() => state.housingReducer.areaBvo.buy * bvoToVvoMultiplier,
									[ state.housingReducer.areaBvo.buy ]
								)}
								onValueChange={({ floatValue }, { event }) => {
									if (event) {
										dispatch({
											type: 'housing/areaVvo',
											name: 'buy',
											value: floatValue,
										});
									}
								}}
								warnMax={useMemo(
									() => state.housingReducer.areaBvo.buy * (bvoToVvoMultiplier + 0.07),
									[ state.housingReducer.areaBvo.buy ]
								)}
								warnMaxText='Het woonoppervlak komt erg dicht in de buurt of gaat over het bruto vloeroppervlak'
							/>
						</ShowConditional>

						<p className='remark mt-4'>
							Hoeveelheid ruimte die de huurder krijgt. Gemiddeld is dit zo'n 80% van het brutovloeroppervlak (bvo). De vormfactor is de verhouding tussen het brutovloeroppervlak en het bruikbaar woonoppervlak.
						</p>
						<p className='remark mt-4'>
							In dit geval is de vormfactor afgerond{' '}
							<FormattedNumber value={vormRatio(state) * 100} decimalScale={0} />%
						</p>
						{vormRatio(state) < 0.8 && (
							<div className='col-span-12'>
								<Alert type={AlertType.Warning} text='Let op! Momenteel ligt de vormfactor onder de 80%, controleer of dit klopt, dit is nogal inefficient.' />
							</div>
						)}
						{vormRatio(state) > 0.9 && (
							<div className='col-span-12'>
								<Alert type={AlertType.Warning} text='Let op! Momenteel ligt de vormfactor boven de 90%, dit is mogelijk, maar dan moet er wel extreem efficient gebouwd worden. Controleer of dit realistisch is.' />
							</div>
						)}
					</div>

					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<div style={{ maxWidth: '300px', flex: 1 }}>
							<SurfaceComparison
								surfaces={[
									noHouses.social == 0 ? null : { area: state.housingReducer.areaVvo.social, color: '#C7D2FE' },
									noHouses.freeSector == 0 ? null : { area: state.housingReducer.areaVvo.freeSector, color: '#818CF8' },
									noHouses.buy == 0 ? null : { area: state.housingReducer.areaVvo.buy, color: '#4F46E5' },
								].filter(x => x != null)}
								maxArea={totalMaxArea}
							/>
						</div>
					</div>
				</div>
			</div>
		</Step>
	);
}
