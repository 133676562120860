import Modal from '../lib/Modal';

import logoKantelingen from '../../assets/kantelingen-logo.png';
import logoP31 from '../../assets/Platform31_logo.png';
import logoAtrocit from '../../assets/logo-v2-fit.png';

export default function AboutModal({ open, onRequestClose }) {
	return <Modal title="Over de haalbaarheidsscan" onRequestClose={onRequestClose} isOpen={open} maxWidth="700px">
		<p className="longform-text" style={{ paddingBottom: '4px' }}>
			De online haalbaarheidsscan is ontwikkeld voor het programma <a href="https://www.platform31.nl/thema-s/wonen-zorg/woonvarianten-voor-senioren">Woonvarianten Senioren</a>
			&nbsp;door:</p>
		<p className="longform-text">
			<ul>
				<li><a href="https://kantelingen.nl/" target="_blank" rel="noreferrer">Kantelingen</a> (Jasper Klapwijk, vastgoedexpert);</li>
				<li><a href="https://atrocit.nl/" target="_blank" rel="noreferrer">Atrocit</a> (Rick Lubbers, softwareontwikkelaar);</li>
				<li>en <a href="https://platform31.nl" target="_blank" rel="noreferrer">Platform31</a> (Annette Duivenvoorden, projectleider wonen en zorg)</li>
			</ul>
			en is gebaseerd op de publicatie <a href="https://www.platform31.nl/publicaties/woonvarianten-voor-senioren">Woonvarianten voor senioren; stappenplan voor het toetsen van financiële haalbaarheid</a>
		</p>
		<br /><br />
		<div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
			<a href="https://platform31.nl/" target="_blank" rel="noreferrer"><img src={logoP31} alt="Logo Platform31" style={{ height: '36px', width: 'auto' }} /></a>
			<a href="https://kantelingen.nl/" target="_blank" rel="noreferrer"><img src={logoKantelingen} alt="Logo Kantelingen" /></a>
			<a href="https://atrocit.nl/" target="_blank" rel="noreferrer"><img src={logoAtrocit} alt="Logo Atrocit" style={{ height: '50px', width: 'auto' }} /></a>
		</div>
	</Modal>;
}