import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
	GridComponent,
	LegendComponent,
	LegendScrollComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	VisualMapComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { useContext } from 'react';
import { AutoSizer } from 'react-virtualized';
import { cashFlowFollowingYears, years } from '../../../reducers/calculations/cashFlow';
import { priceBuyHome, saleIncomeFromBuyHomes } from '../../../reducers/calculations/incomeCalculation';
import { vormRatio } from '../../../reducers/housingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { FormattedNumber } from '../../lib/FormattedNumber';
import Modal from 'react-modal';
import { irr } from 'financial';
import { BARFreeSector, BARSocial } from '../../../reducers/indicators';
import { loanToValue } from '../../../reducers/financingReducer';
import { IndicatorIcon } from '../../Sidebar';
import { serviceCostsFreeSector, serviceCostsSocialHousing } from '../../../reducers/serviceReducer';
import Button from '../../lib/forms/Button';
import { VALUE_STEP } from '../s9-value/ValuationStep';
import ExportableAsPng from '../../lib/ExportableAsPng';
import { rentalIncomeFreeSectorPerMonth, rentalIncomeSocialPerMonth } from '../../../reducers/incomeReducer';
import ShowConditional from '../../lib/ShowConditional';
import ButtonArea from '../../lib/forms/ButtonArea';
import Indicators from './Indicators';

echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	LineChart,
	ToolboxComponent,
	LegendComponent,
	LegendScrollComponent,
	VisualMapComponent,
	SVGRenderer,
]);

export const RESULT_STEP = 'result';

export function ResultSection() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);
	const result = cashFlowFollowingYears(state);

	console.log('Cash flow calculation', result);

	const firstYearUnderZero = result.findIndex((x) => x.total < 0);
	const terugVerdienTijd = result.findIndex((x) => x.totalWithoutFinancing >= 0);

	return (
		<>
			<Modal
				isOpen={state.stepReducer.step == RESULT_STEP}
			    onRequestClose={() => {
				    dispatch({ type: 'step/updateStep', step: VALUE_STEP });
				}}
				style={{ content: { border: 'none', boxShadow: '1px 2px 16px rgba(0, 0, 0, 0.3)' }, overlay: { background: 'rgba(0, 0, 0, 0.5)' } }}>
				<div style={{ padding: '16px 24px' }}>
					<h1>Resultaten Haalbaarheidsscan</h1>
					<p className="text-lg text-coolGray-600 pt-4" style={{ maxWidth: '50rem' }}>Deze informatie kun je als hulpmiddel gebruiken bij gesprekken met adviseurs, financierders, en de gemeente.</p>
					<br />
					<ExportableAsPng fileName="haalbaarheidsscan" btnAbove={true}>
						<div style={{ display: 'grid', gridTemplateColumns: '260px 1fr 1fr', gap: '24px' }}>
							<div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px' }}><Indicators /></div>

							<div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px' }}>
								<h2 className="text-xl font-medium text-coolGray-600">Kasstroom</h2>
								<br />

								<div style={{ height: 'calc(100% - 48px)' }}>
									<AutoSizer>
										{({ height, width }) => (
											<ReactEChartsCore
												echarts={echarts}
												option={{
													title: {
														text: '',
														left: '1%',
													},
													tooltip: {
														trigger: 'axis',
													},
													grid: {
														left: '5%',
														right: '15%',
														bottom: '10%',
													},
													xAxis: {
														data: years(state),
														axisLabel: {
															// rotate: 30,
														},
													},
													yAxis: {
														axisLabel: {
															formatter: (value) => parseFloat(value).toLocaleString('nl-NL', {
																style: 'currency',
																currency: 'EUR',
																notation: 'compact',
															}),
															align: 'center',
														},
													},
													toolbox: {
														right: 20,
														feature: {
															saveAsImage: {
																title: 'Exporteren',
															},
														},
													},
													legend: {
														type: 'scroll',
														orient: 'horizontal',
														top: 0,
														left: 'center',
														data: [
															{
																name: 'Liquiditeit',
															},
															{
																name: 'Investering terugverdienen',
															},
														],
													},
													visualMap: [
														{
															// For total
															seriesIndex: 0,
															show: false,
															type: 'piecewise',
															pieces: [
																{
																	min: 1,
																	color: '#3fce07',
																},
																{
																	max: 0,
																	color: '#da4242',
																},
															],
															outOfRange: {
																color: '#999',
															},
														},
														{
															// For total zonder financiering
															seriesIndex: 1,
															show: false,
															type: 'piecewise',
															pieces: [
																{
																	min: 1,
																	color: '#70d749',
																},
																{
																	max: 0,
																	color: '#fa6565',
																},
															],
															outOfRange: {
																color: '#999',
															},
														},
													],
													series: [
														{
															name: 'Liquiditeit',
															type: 'line',
															symbol: 'rect',
															color: '#9a9a9a',
															symbolSize: 6,
															data: result.map((x) => x.total),
															tooltip: {
																valueFormatter: (value) => parseFloat(value).toLocaleString('nl-NL', {
																	style: 'currency',
																	currency: 'EUR',
																	maximumFractionDigits: 0,
																}),
															},
														},
														{
															name: 'Investering terugverdienen',
															type: 'line',
															symbol: 'circle',
															symbolSize: 6,
															color: '#9a9a9a',
															data: result.map((x) => x.totalWithoutFinancing),
															tooltip: {
																valueFormatter: (value) => parseFloat(value).toLocaleString('nl-NL', {
																	style: 'currency',
																	currency: 'EUR',
																	maximumFractionDigits: 0,
																}),
															},
														},
													],
												}}
												style={{ height, width }}
											/>
										)}
									</AutoSizer>
								</div>
							</div>
							{/* <div></div>*/}
							 <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px' }}>
								 <h2>Beschrijving</h2>

								 <ResultQuestionAnswer
									 question='Hoe lang duurt het om de investering terug te verdienen?'
									 answer={
										 terugVerdienTijd > -1
											 ? `De investering is in ${
												 years(state)[terugVerdienTijd]
											 } (${terugVerdienTijd} jaar) terugverdiend.`
											 : 'Helaas is de terugverdientijd te lang, de investering wordt niet binnen de exploitatietermijn terugverdiend.'
									 }
								 />
								 <ResultQuestionAnswer
									 question='Zijn de leningskosten te betalen met het netto-inkomen uit huuropbrengsten?'
									 answer={
										 firstYearUnderZero > -1
											 ? `Nee, in ${
												 years(state)[firstYearUnderZero]
											 } is de kasstroom (voor het eerst) negatief, waardoor de leningen helaas niet te betalen zijn.`
											 : 'Ja, de kasstroom wordt nooit negatief!'
									 }
								 />
								 <ShowConditional condition={state.housingReducer.noHouses.buy > 0}>
									 <ResultQuestionAnswer
										 question='Wat is de opbrengst voor de ontwikkelaar uit de verkoop van koopwoningen?'
										 answer={<span><FormattedNumber prefix='€ ' value={saleIncomeFromBuyHomes(state)} />{saleIncomeFromBuyHomes(state) > 0 && <>(<FormattedNumber prefix='€' value={priceBuyHome(state)} /> per koopwoning)</>}</span>} />
								 </ShowConditional>
								 <ResultQuestionAnswer
									 question='Wat is de vormfactor van het project (GO/BVO)?'
									 answer={<span><FormattedNumber value={vormRatio(state) * 100} decimalScale={0} />%</span>} />

							 </div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '24px', marginTop: '24px', maxWidth: '1200px', width: '100%' }}>
								<ShowConditional condition={state.housingReducer.noHouses.social > 0}>
									<div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px' }}>
										<h3>Sociale huur</h3>
										<span className="bold-value"><FormattedNumber prefix='€ ' value={rentalIncomeSocialPerMonth(state)} /></span> p/m huur<br />
										<span className="bold-value"><FormattedNumber prefix='€ ' value={serviceCostsSocialHousing(state)} /></span> p/m servicekosten<br />
										<span className="bold-value"><FormattedNumber value={state.housingReducer.areaVvo.social} /></span> m² woonoppervlakte<br />
									</div>
								</ShowConditional>

								<ShowConditional condition={state.housingReducer.noHouses.freeSector > 0}>
									<div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px' }}>
										<h3>Vrije sector huur</h3>
										<span className="bold-value"><FormattedNumber prefix='€ ' decimalScale={0} value={rentalIncomeFreeSectorPerMonth(state)} /></span> p/m huur<br />
										<span className="bold-value"><FormattedNumber prefix='€ ' value={serviceCostsFreeSector(state)} /></span> p/m servicekosten<br />
										<span className="bold-value"><FormattedNumber value={state.housingReducer.areaVvo.social} /></span> m² woonoppervlakte<br />
									</div>
								</ShowConditional>

								<ShowConditional condition={state.housingReducer.noHouses.buy > 0}>
									<div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px' }}>
										<h3>Koopwoningen</h3>

										<span className="bold-value"><FormattedNumber prefix='€ ' value={priceBuyHome(state)} /></span> VON huizenprijs<br />
										<span className="bold-value"><FormattedNumber prefix='€ ' value={state.housingReducer.areaVvo.buy} /></span>  m² woonoppervlakte<br />
									</div>
								</ShowConditional>
							</div>
						</div>
					</ExportableAsPng>
					<ButtonArea>
						<Button
							primary
							label='Aanpassen ›'
							onClick={() => {
								dispatch({ type: 'step/updateStep', step: VALUE_STEP });
							}} />
					</ButtonArea>
				</div>
			</Modal>
		</>
	);
}

function ResultQuestionAnswer({ question, answer }) {
	return <div style={{ paddingTop: 'var(--u-16)' }}>
		<div>{question}</div>
		<div style={{ fontWeight: '500', color: 'var(--col-grey-800)', paddingBottom: 'var(--u-8)', paddingTop: 'var(--u-4)' }}>{answer}</div>
	</div>;
}
