import { createSelector } from 'reselect';

export function incomeReducer(state, action) {
	switch (action.type) {
	case 'income/setValue': {
		return {
			...state,
			incomeReducer: {
				...state.incomeReducer,
				[action.name]: action.value,
			},
		};
	}
	case 'income/setExplotationTermPeriodYears': {
		return {
			...state,
			incomeReducer: {
				...state.incomeReducer,
				explotationTermPeriodYears: action.value,
			},
			financingReducer: {
				...state.financingReducer,
				mortgage: {
					...state.financingReducer.mortgage,
					years: action.value,
					periods: action.value,
				},
				softLoans: {
					...state.financingReducer.softLoans,
					years: action.value,
					periods: action.value,
				},
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const initialIncomeReducer = {
	incomeReducer: {
		rentalIncomeSocialPerMonth: null,
		rentalIncomeSocialInflationPerYear: 2,

		rentalIncomeFreeSectorPerMonth: null,
		rentalIncomeFreeSectorInflationPerYear: 2,

		rentalIncomeCommonAreaPerDayPart: 40,
		rentalIncomeCommonAreaTimesPerYear: 50,
		rentalIncomeCommonAreaInflationPerYear: 2,

		energyPerformanceFeePerM2VVoPerHouse: 0,
		energyPerformanceFeePerM2VVoInflationPerYear: 2,

		lossToVacantHousesPercentOfTheRentalIncomeServiceCostsAndEnergyPerformanceFee: 2,

		explotationFromYear: new Date().getFullYear() + 1,
		explotationTermPeriodYears: 30,

		// capitalRequirementInitialInvestment: 4_717_000,
	},
};

export const income = (state) => state.incomeReducer;
const housing = (state) => state.housingReducer;
// export const incomePerRentalHouses = createSelector(
// 	income,
// 	housing,
// 	(income, housing) =>
// 		income.capitalRequirementInitialInvestment / (housing.noHouses.social + housing.noHouses.freeSector)
// );

export const rentalIncomeSocialPerMonth = createSelector(
	housing,
	income,
	(housing, income) => income.rentalIncomeSocialPerMonth ?? ((housing.municipality?.rentExpectation?.social ?? 0) * housing.areaVvo.social)
);

export const rentalIncomeFreeSectorPerMonth = createSelector(
	housing,
	income,
	(housing, income) => income.rentalIncomeFreeSectorPerMonth ?? ((housing.municipality?.rentExpectation?.freeSector ?? 0) * housing.areaVvo.freeSector)
);

export const vacancyLoss = createSelector(
	income,
	(income) => 1 - income.lossToVacantHousesPercentOfTheRentalIncomeServiceCostsAndEnergyPerformanceFee / 100
);

// Kasstroom P6 ofwel: Canvas G24
// DEZE NODIG
export const incomeRentalSocialHousingFirstYear = createSelector(
	income,
	housing,
	vacancyLoss,
	rentalIncomeSocialPerMonth,
	(income, housing, vacancyLoss, rentalIncomeSocialPerMonth) => rentalIncomeSocialPerMonth * housing.noHouses.social * 12 * vacancyLoss
);
// DEZE NODIG
export const energyPerformanceFeeSocialHousing = createSelector(
	income,
	housing,
	(income, housing) => income.energyPerformanceFeePerM2VVoPerHouse * housing.noHouses.social * housing.areaVvo.social
);

export const energyPerformanceFeeFreeSector = createSelector(
	income,
	housing,
	(income, housing) => income.energyPerformanceFeePerM2VVoPerHouse * housing.areaVvo.freeSector * housing.noHouses.freeSector
);

export const incomeFreeSectorHousingFirstYear = createSelector(
	income,
	housing,
	vacancyLoss,
	rentalIncomeFreeSectorPerMonth,
	(income, housing, vacancyLoss, rentalIncomeFreeSectorPerMonth) => rentalIncomeFreeSectorPerMonth * housing.noHouses.freeSector * 12 * vacancyLoss
);

export const incomeCommonArea = createSelector(
	income,
	(income) => income.rentalIncomeCommonAreaPerDayPart * income.rentalIncomeCommonAreaTimesPerYear
);
