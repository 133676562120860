import { useContext } from 'react';

import { totalNoOfHouses } from '../../../reducers/housingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { Alert, AlertType } from '../../lib/Alert';
import { CalculationTableRowTerm } from '../../lib/calctable/CalculationTableRowTerm';
import { CostColumn } from '../../lib/costrow/CostColumn';
import { CostInput } from '../../lib/costrow/CostInput';
import { CostRow, CostRowFullWidth } from '../../lib/costrow/CostRow';
import HorizontalColumnChart from '../../lib/HorizontalColumnChart';
import { Step } from '../Step';
import MunicipalitySelector from './MunicipalitySelector';
import { UNIT_STEP } from '../s2-units/UnitLayoutStep';

function setIncomeValue(name, dispatch) {
	return ({ floatValue }, { event }) => {
		if (event) {
			dispatch({
				type: 'income/setValue',
				name,
				value: floatValue,
			});
		}
	};
}

export const PROJECT_STEP = 'project';

export function ProjectStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	return (
		<Step
			name='Project'
			id={PROJECT_STEP}
			description='Waar, wanneer, en wat: hoe komt je project er uit te zien?'
			icon='clarity:building-solid'
			showContent={state.stepReducer.step === PROJECT_STEP}
			nextStepValidations={[
				{
					valid: (state.housingReducer.noHouses.social + state.housingReducer.noHouses.freeSector) > 0,
					error: 'Er moet minstens één type huurwoning gebouwd worden',
				},
				{
					valid: state.housingReducer.municipality != null,
					error: 'Gemeente moet geselecteerd zijn',
				},
				{
					valid: state.incomeReducer.explotationFromYear != null,
					error: 'Eerste exploitatiejaar moet ingevuld zijn',
				},
				{
					valid: state.incomeReducer.explotationTermPeriodYears != null && state.incomeReducer.explotationTermPeriodYears >= 3,
					error: 'Exploitatieduur moet ingevuld en minstens 3 jaar zijn',
				},
			]}
			thisStep={PROJECT_STEP}
			nextStep={UNIT_STEP}
			summaryComponent={
				<div>
					<div>
						<span className='bold-value'> {totalNoOfHouses(state)}</span><span> woningen te bouwen in </span><span className='bold-value'>{state.housingReducer.municipality?.name}</span>
						<br />
						<small>
							waarvan <span className='bold-value'>{state.housingReducer.noHouses.social}</span> sociale
							huur, <span className='bold-value'>{state.housingReducer.noHouses.freeSector}</span> vrije
							sector, en <span className='bold-value'>{state.housingReducer.noHouses.buy}</span>{' '}
							koopwoningen
						</small>
					</div>
				</div>
			}
			summaryInError={totalNoOfHouses(state) == null || isNaN(totalNoOfHouses(state))}>

			<div className='col'>
				<div>
					<h3>Gemeente</h3>

					<div style={{ maxWidth: '300px' }}>
						<MunicipalitySelector
							onChange={(mId, municipality) => dispatch({	type: 'housing/setMunicipality', municipality })}
							value={state.housingReducer.municipality?.id} />
					</div>

					<p className="remark mt-4">Staat uw gemeente er niet tussen? Kies dan een vergelijkbare gemeente of 'Nederlands Gemiddelde'. De gemeente-afhankelijke gegevens (gemiddelde WOZ-waarden, OZB-tarief, rioolheffing etc.) zijn later zelf aan te passen.</p>
				</div>
			</div>

			<div className="hor-separator" />

			<div className="col-2">
				<div>
					<h3>Woningverdeling</h3>
					<div style={{ paddingBottom: '4px' }}>
						<CalculationTableRowTerm
							value={state.housingReducer.noHouses.social}
							unit='&times; sociale huur'
							decimals={0}
							editable={true}
							onValueChange={({ floatValue }, { event }) => {
								if (event) {
									dispatch({
										type: 'housing/noHouses',
										name: 'social',
										value: floatValue,
									});
								}
							}}
						/>
					</div>

					<div style={{ paddingBottom: '4px' }}>
						<CalculationTableRowTerm
							value={state.housingReducer.noHouses.freeSector}
							unit='&times; vrije sector huur'
							decimals={0}
							editable={true}
							onValueChange={({ floatValue }, { event }) => {
								if (event) {
									dispatch({
										type: 'housing/noHouses',
										name: 'freeSector',
										value: floatValue,
									});
								}
							}}
						/>
					</div>

					<div style={{ paddingBottom: '4px' }}>
						<CalculationTableRowTerm
							value={state.housingReducer.noHouses.buy}
							unit='&times; koopwoningen'
							decimals={0}
							editable={true}
							onValueChange={({ floatValue }, { event }) => {
								if (event) {
									dispatch({
										type: 'housing/noHouses',
										name: 'buy',
										value: floatValue,
									});
								}
							}}
						/>
					</div>
				</div>

				<div>
					<div style={{ paddingTop: '40px' }}>
						<HorizontalColumnChart
							maxWidth={400}
							columns={[
								{
									color: '#C7D2FE',
									value: state.housingReducer.noHouses.social || 0.02,
									label: 'Sociale huurwoningen',
								},
								{
									color: '#818CF8',
									value: state.housingReducer.noHouses.freeSector || 0.02,
									label: 'Vrije sector',
								},
								{
									color: '#4F46E5',
									value: state.housingReducer.noHouses.buy || 0.02,
									label: 'Koopwoningen',
								},
							]}
						/>
					</div>
				</div>
			</div>

			<div className="hor-separator" />

			<CostRow name='Exploitatie' cols={2}>
				{state.incomeReducer.explotationTermPeriodYears > 30 && <CostRowFullWidth>
					<Alert
						type={AlertType.Warning}
						text="De termijn verhogen maakt een project moeilijker te financiëren. Probeer eerst andere maatregelen te nemen, zoals het verhogen van de huur, of verlagen van kosten." />
				</CostRowFullWidth>}

				<CostColumn icon='mdi:calendar-blank' name='Aanvang'>
					<CostInput
						value={state.incomeReducer.explotationFromYear}
						decimals={0}
						thousandSeparator=''
						onValueChange={setIncomeValue('explotationFromYear', dispatch)}
					/>
				</CostColumn>

				<CostColumn icon='mdi:calendar-clock' name='Termijn'>
					<CostInput
						value={state.incomeReducer.explotationTermPeriodYears}
						unit='jaar'
						decimals={0}
						strictMin={15}
						helpText='Gebruikelijk is 20 of 30 jaar'
						onValueChange={({ floatValue }, { event }) => {
							if (event) {
								dispatch({
									type: 'income/setExplotationTermPeriodYears',
									value: floatValue,
								});
							}
						}} />
				</CostColumn>
			</CostRow>
		</Step>
	);
}
