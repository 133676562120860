import { createSelector } from 'reselect';

import { incomeFreeSectorHousingFirstYear, incomeRentalSocialHousingFirstYear } from './incomeReducer';
import { buildCostsFreeSectorTotal, buildCostsSocialTotal } from './investingReducer';

export const BARSocial = createSelector(incomeRentalSocialHousingFirstYear, buildCostsSocialTotal, (income, costs) => {
	return income / costs;
});

export const BARFreeSector = createSelector(
	incomeFreeSectorHousingFirstYear,
	buildCostsFreeSectorTotal,
	(income, costs) => income / costs
);
