import Modal from '../lib/Modal';
import FaqCollapsableArea from './FaqCollapsableArea';

export default function FaqModal({ open, onRequestClose }) {
	return <Modal isOpen={open} onRequestClose={onRequestClose} title="Frequently Asked Questions" maxWidth="700px">
		<br />
		<FaqCollapsableArea label="Wat zijn de voordelen van de haalbaarheidsscan?">
			<p>
				<ul>
					<li>Een snelle, eerste indicatie*) van de haalbaarheid van uw initiatief in de eerste fase van bouwplanontwikkeling.</li>
					<li>Mogelijkheid om in deze fase varianten van verschillende woonprogramma’s (aantallen en prijsstelling voor huur en koop) te vergelijken</li>
					<li>Handvatten voor uw gesprek met uw initiatiefgroep, gemeente, andere partijen, banken en geldverstrekkers over grond en financiering.</li>
					<li>De uitkomsten van de ingevulde haalbaarheidsscan komen beschikbaar in een pdf bestand</li>
				</ul>
			</p>
			<p>
				*) Disclaimer: let op: de scan geeft uw vroegtijdig een eerste indicatie, maar géén garantie van de financiële haalbaarheid van uw initiatief. Dat is in deze fase onmogelijk, omdat er nog veel onzeker is.
			</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Waarvan toetst u de haalbaarheid?">
			<p>De haalbaarheidsscan geeft een indicatie van <a href="https://www.platform31.nl/publicaties/woonvarianten-voor-senioren">de haalbaarheid op basis van de woningmix</a>.
				U vult gegevens in voor de mix aan woningen in uw bouwplan. De woningen kunnen verschillen in oppervlak en segment (sociale huur, middenhuur-, vrije sector - en koop).</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Voor wie is de haalbaarheidsscan?">
			<p>De scan is vrij beschikbaar en bruikbaar voor alle partijen die betrokken zijn bij de totstandkoming van nieuwe woonvarianten voor senioren: particulier collectief initiatief in dorpen en buurten, wooncoöperatieven, sociaal ondernemers, architecten, adviseurs en procesbegeleiders, conceptontwikkelaars, corporaties, ontwikkelaars en gemeenten.</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Voor welke fase is de scan geschikt?">
			<p>De scan is bedoeld voor de initiatieffase van een bouwplanontwikkeling, voor het verkennen van de haalbaarheid van de ideeën die er zijn. Ook in een latere fase kan deze worden ingezet.</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Gezamenlijk of alleen invullen?">
			<p>Vul stap voor stap de gegevens in over het initiatief, zoals de projectinformatie, de investering, inkomsten en financiering. Dit resulteert in een samenvattend overzicht van de gegevens van het project, doorberekend naar effectief rendement, bruto aanvangsrendement, leninglastendekking en waardeverhouding.</p>
			<br />
			<p>Het loont om deze haalbaarheidsscan in te vullen met de leden van uw projectgroep. Het gesprek hierover maakt duidelijk: wat weten we al, bedoelen we hetzelfde en hebben we hetzelfde beeld voor ogen? De gevraagde gegevens zetten het projectteam ook alvast tot  nadenken over onderdelen, die pas later in de tijd worden bepaald. Denk aan bijvoorbeeld aan de servicekosten en beheer, maar ook het ontwerp en de aanleg van de tuin. Die kosten komen er later bij.</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Hoe veel tijd kost het invullen?">
			<p>Bij het testen met 4 initiatiefnemers duurde het invullen van de haalbaarheidsscan ongeveer 1 tot 1,5 uur.</p>
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Welke gegevens moet ik paraat hebben?">
			<p style={{ paddingBottom: '4px' }}>Projectinformatie:</p>
			<ul>
				<li>Het soort en aantal woningen (koop, huur en vrije sector)</li>
				<li>Het woningtype (appartement, grondgebonden woning, aantal lagen)</li>
				<li>Oppervlakte van de woningen (bruto vloeroppervlak en woonoppervlak). Bij verschillende afmetingen, vult u de gemiddelde oppervlakte in</li>
				<li>Oppervlakte van de gemeenschappelijke ruimten</li>
				<li>Oppervlakte van de tuinen</li>
				<li>Aantal parkeerplekken</li>
				<li>Exploitatietermijn</li>
			</ul>
			Een deel van de gegevens is automatisch ingevuld op basis van normkosten die bekend zijn vanuit landelijke kengetallen of gelden voor uw gemeente. U kunt deze gegevens wijzigen.
		</FaqCollapsableArea>
		<FaqCollapsableArea label="Met wie is de haalbaarheidsscan getest?">
			<p style={{ paddingBottom: '4px' }}>In de periode maart-april 2022 is de scan getest met:</p>
			<ul>
				<li><a href="https://woonstichtingnuvoorstraks.nl/">Austerlitz Zorgt / Hart van Austerlitz</a>, Jan Snijders, burgerinitiatief Woonstichting Nu voor Straks (gerealiseerd voorbeeld). <a href="https://youtu.be/SkCoCvyteq4" target="_blank" rel="noreferrer">Bekijk hier de reactie van Jan.</a></li>
				<li><a href="https://doorachterveld.nl/">DAVA</a>, Achterveld, Ko van der Ham, procesbegeleider en architect, Raban Haaijk, Bureau Bos) (initiatieffase). <a href="https://youtu.be/RfUjWlj9QOQ">Bekijk hier de reactie van Ko.</a></li>
				<li><a href="https://groen-grijsrooi.nl/">Groen-grijs rooi</a>, Sint Oedenrode, Mieke van Lankveld, Antoinet Voogt en Maurice van Osch (initiatieffase)</li>
				<li><a href="https://www.debundel.co/">De Bundel</a>, wooncoöperatie in Amsterdam West, Andrea Verdecchia en Mira Nekova, Time to Access (planvormingsfase)</li>
			</ul>
		</FaqCollapsableArea>
	</Modal>;
}