// Explotation costs: Maintenance + Management + Service costs

import { createSelector } from 'reselect';

import {
	maintenaceCostsCommonArea,
	maintenanceCostsFreeSector,
	maintenanceCostsSocial,
	managementFreeSectorCosts,
	managementSocialCosts,
} from '../investingReducer';
import { serviceCostsCommonArea, serviceCostsFreeSector, serviceCostsSocialHousing } from '../serviceReducer';
export const explotationCostsSocial = createSelector(
	maintenanceCostsSocial,
	managementSocialCosts,
	serviceCostsSocialHousing,
	(maintenanceCosts, managementCosts, serviceCosts) => {
		return maintenanceCosts + managementCosts + serviceCosts;
	}
);

export const explotationCostsFreeSector = createSelector(
	maintenanceCostsFreeSector,
	managementFreeSectorCosts,
	serviceCostsFreeSector,
	(maintenanceCosts, managementCosts, serviceCosts) => {
		return maintenanceCosts + managementCosts + serviceCosts;
	}
);

export const explotationCostsCommonArea = createSelector(
	maintenaceCostsCommonArea,
	serviceCostsCommonArea,
	(maintenance, serviceCosts) => {
		return maintenance + serviceCosts;
	}
);

export const totalExplotationCostsMinusSecondYear = createSelector(
	explotationCostsSocial,
	explotationCostsFreeSector,
	explotationCostsCommonArea,
	(social, freeSector, commonArea) => {
		return -social - freeSector; // Appareantly no common area
	}
);
