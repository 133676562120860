import { Icon } from '@iconify/react';
import { FormattedNumber } from '../../lib/FormattedNumber';
import { Tooltip } from '../../lib/Tooltip';

export default function IndicatorPill({ value, suffix, decimals = 2, error, warn, ok, nanAllowed = false, rawValue = false, infoError, infoWarn, infoOk }) {
	if (isNaN(value) && !nanAllowed) {
		return <div className='flex w-28 rounded-full h-8 bg-blue-100 text-blue-700 items-center px-2 font-semibold indicator-pill indicator-pill-unknown'>
			<Icon icon='ant-design:question-outlined' className='indicator-pill-icon' />
			{!isNaN(value) && (
				<FormattedNumber
					decimalScale={decimals}
					className='inline-block justify-center px-2'
					value={value}
					suffix={suffix}
				/>
			)}
		</div>;
	}

	if (error(value)) {
		return <Tooltip text={infoError}>
			<div className='flex w-28 rounded-full h-8 bg-red-100 text-red-700 items-center px-2 font-semibold indicator-pill indicator-pill-error'>
				<Icon icon='carbon:error-filled' className='indicator-pill-icon' />
				{rawValue ? value : <FormattedNumber
					decimalScale={decimals}
					className='inline-block justify-center px-2'
					value={value}
					suffix={suffix}
				/>}
			</div>
		</Tooltip>;
	}

	if (warn(value)) {
		return <Tooltip text={infoWarn}>
			<div className='flex w-28 rounded-full h-8 bg-orange-100 text-orange-700 items-center px-2 font-semibold indicator-pill indicator-pill-warn'>
				<Icon icon='ant-design:warning-filled' className='indicator-pill-icon' />
				{rawValue ? value : <FormattedNumber
					decimalScale={decimals}
					className='inline-block justify-center px-2'
					value={value}
					suffix={suffix}
				/>}
			</div>
		</Tooltip>;
	}

	if (ok(value)) {
		return <Tooltip text={infoOk}>
			<div className='flex w-28 rounded-full h-8 bg-green-100 text-green-700 items-center px-2 font-semibold indicator-pill indicator-pill-ok'>
				<Icon icon='akar-icons:check' className='indicator-pill-icon' />
				{rawValue ? value : <FormattedNumber
					decimalScale={decimals}
					className='inline-block justify-center px-2'
					value={value}
					suffix={suffix}
				/>}
			</div>
		</Tooltip>;
	}

	return null;
}
