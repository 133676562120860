import React from 'react';

function cmpSurface(a, b) {
	return b.area - a.area;
}

export default function SurfaceComparison({ surfaces, maxArea }) {
	const actualMaxArea = maxArea || Math.max(...surfaces.map((s) => s.area));

	return (
		<div
			className='surface-cmp-container'
			style={{
				width: '100%',
				paddingTop: '62%',
				position: 'relative',
				overflow: 'hidden',
				boxShadow: '0 0 4px inset rgba(0, 0, 0, 0.3)',
			}}>
			{surfaces.sort(cmpSurface).map((surface) => (
				<div
					className='surface-cmp-surf transition-all'
					style={{
						background: surface.color,
						width: (surface.area / actualMaxArea) * 100 + '%',
						height: (surface.area / actualMaxArea) * 100 + '%',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						boxShadow: '0 0 4px rgba(0, 0, 0, 0.25), 0 0 16px rgba(0, 0, 0, 0.2)',
					}}
				/>
			))}
		</div>
	);
}
