import { createSelector } from 'reselect';

import { building } from './buildingReducer';
import { housing, totalNoOfHouses } from './housingReducer';
import { totalTerrainArea } from './terrainReducer';
import { totalWOZFreeSector, totalWOZSocial } from './valueReducer';

export function investingReducer(state, action) {
	switch (action.type) {
	case 'housing/setMunicipality': {
		const ir = state.investingReducer;
		return {
			...state,
			investingReducer: {
				...state.investingReducer,
				buildCostSocialGround: action.municipality?.landCost?.social || ir.buildCostSocialGround,
				buildCostSocialFloor: action.municipality?.buildCost?.social || ir.buildCostSocialFloor,
				buildCostFreeSectorGround: action.municipality?.landCost?.freeSector || ir.buildCostFreeSectorGround,
				buildCostFreeSectorFloor: action.municipality?.buildCost?.freeSector || ir.buildCostFreeSectorFloor,
				buildCostBuyGround: action.municipality?.landCost?.buy || ir.buildCostBuyGround,
				buildCostBuyFloor: action.municipality?.buildCost?.buy || ir.buildCostBuyFloor,
				taxOZBPropertyOwners: action.municipality?.taxRateOzb || ir.taxOZBPropertyOwners,
				taxSewageChargesPerHousePerYear: action.municipality?.taxSewage || ir.taxSewageChargesPerHousePerYear,
				taxWaterSystemLevyPercentOfWOZ: action.municipality?.taxRateWaterSystem || ir.taxWaterSystemLevyPercentOfWOZ,
				groundLeaseRate: action.municipality?.groundLeaseRate || ir.groundLeaseRate,
			},
		};
	}
	case 'investing/setValue': {
		return {
			...state,
			investingReducer: {
				...state.investingReducer,
				[action.name]: action.value,
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const initialInvestingReducerState = {
	investingReducer: {
		buildCostSocialGround: 250,
		buildCostSocialFloor: 2900,

		buildCostFreeSectorGround: 340,
		buildCostFreeSectorFloor: 3300,

		buildCostBuyGround: 350,
		buildCostBuyFloor: 3500,

		groundLeaseAmount: null,
		groundLeaseRate: 2.39,
		groundLeaseIndexation: 2,

		maintenancePerM2BvoPerYear: 12,
		// Maintenance inflation is really service cost inflation because service cost has a zero impact on the business case (is both income and expense, so net zero)

		taxOZBPropertyOwners: 0.0356,
		taxSewageChargesPerHousePerYear: 400,
		taxWaterSystemLevyPercentOfWOZ: 0.0122,

		insuranceCARPercent: 0.05,

		mutationRatePercentPerYear: 10,
		mutationCostSocial: 400,
		mutationCostFreeSector: 400,
	},
};

const investing = (state) => state.investingReducer;

export const buildCostsFloorFreeSector = createSelector(
	housing,
	investing,
	(housing, investing) => housing.noHouses.freeSector * investing.buildCostFreeSectorFloor * housing.areaBvo.freeSector
);

export const buildingFloorCostsSocial = createSelector(
	housing,
	investing,
	(housing, investing) => housing.noHouses.social * investing.buildCostSocialFloor * housing.areaBvo.social
);

// Ofwel de Kasstroom AD5/ Canvas B16+B19 maar dan positief
export const buildCostsSocialTotal = createSelector(housing, investing, (housing, investing) => {
	return (
		housing.noHouses.social *
		(investing.buildCostSocialGround + investing.buildCostSocialFloor) *
		housing.areaBvo.social
	);
});

// Ofwel de Kasstroom AE5 maar dan positief
// D14/H2 * D5*D18. Geen idee waarom de vloerkosten zo gek berekend moeten worden.
export const buildCostsFreeSectorTotal = createSelector(
	housing,
	investing,
	totalNoOfHouses,
	totalTerrainArea,
	(housing, investing, totalNoOfHouses, totalTerrainArea) => {
		return (
			(housing.noHouses.freeSector / totalNoOfHouses) * // D14 // Dat moeten we nog delen door alle huizen blijkbaae
				totalTerrainArea * // D5
				investing.buildCostFreeSectorGround + // D18
			housing.noHouses.freeSector * investing.buildCostFreeSectorFloor * housing.areaBvo.freeSector
		); // + de normale bouwkosten
	}
);

// Kasstroom AF5

export const buildCostsBuy = createSelector(
	housing,
	investing,
	totalNoOfHouses,
	totalTerrainArea,
	(housing, investing, totalNoOfHouses, totalTerrainArea) => {
		return (
			(housing.noHouses.buy / totalNoOfHouses) * // D15 // Dat moeten we nog delen door alle huizen blijkbaae
				totalTerrainArea * // D5
				investing.buildCostBuyGround + // D19
			housing.noHouses.buy * investing.buildCostBuyFloor * housing.areaBvo.buy // + de normale bouwkosten
		);
	}
);

export const totalBuyCosts = createSelector(
	buildCostsBuy,
	buildCostsFreeSectorTotal,
	buildCostsSocialTotal,
	(buildCostsBuy, buildCostsFreeSector, buildCostsSocial) => {
		return buildCostsBuy + buildCostsFreeSector + buildCostsSocial;
	}
);

export const totalBuyCostsSocialAndFreeSectorOnly = createSelector(
	buildCostsSocialTotal,
	buildCostsFreeSectorTotal,
	(buildCostsSocial, buildCostsFreeSector) => {
		return buildCostsSocial + buildCostsFreeSector;
	}
);

export const managementSocialCosts = createSelector(
	totalWOZSocial,
	investing,
	housing,
	buildingFloorCostsSocial,
	(totalWOZSocial, investing, housing, buildingFloorCostsSocial) => {
		const ozb = (totalWOZSocial * investing.taxOZBPropertyOwners) / 100;
		const waterSystemLevy = (totalWOZSocial * investing.taxWaterSystemLevyPercentOfWOZ) / 100;
		const sewageCharges = housing.noHouses.social * investing.taxSewageChargesPerHousePerYear;
		const insurance = (investing.insuranceCARPercent / 100) * buildingFloorCostsSocial;
		const mutation =
			housing.noHouses.social * (investing.mutationRatePercentPerYear / 100) * investing.mutationCostSocial;
		return ozb + waterSystemLevy + sewageCharges + insurance + mutation;
	}
);

export const managementFreeSectorCosts = createSelector(
	totalWOZFreeSector,
	investing,
	housing,
	buildCostsFloorFreeSector,
	(totalWOZFreeSector, investing, housing, buildCostsFloorFreeSector) => {
		const ozb = (totalWOZFreeSector * investing.taxOZBPropertyOwners) / 100;
		const waterSystemLevy = (totalWOZFreeSector * investing.taxWaterSystemLevyPercentOfWOZ) / 100;
		const sewageCharges = housing.noHouses.freeSector * investing.taxSewageChargesPerHousePerYear;
		const insurance = (investing.insuranceCARPercent / 100) * buildCostsFloorFreeSector;
		const mutation =
			housing.noHouses.freeSector *
			(investing.mutationRatePercentPerYear / 100) *
			investing.mutationCostFreeSector;
		return ozb + waterSystemLevy + sewageCharges + insurance + mutation;
	}
);

export const maintenanceCostsSocial = createSelector(
	housing,
	investing,
	(housing, investing) => housing.noHouses.social * investing.maintenancePerM2BvoPerYear * housing.areaBvo.social
);

export const maintenaceCostsCommonArea = createSelector(building, investing, (building, investing) => {
	return building.commonAreaBvo * investing.maintenancePerM2BvoPerYear;
});

export const maintenanceCostsFreeSector = createSelector(
	housing,
	investing,
	(housing, investing) => housing.noHouses.freeSector * investing.maintenancePerM2BvoPerYear * housing.areaBvo.freeSector
);
