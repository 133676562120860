import { useContext } from 'react';

import { buildingFootprint } from '../../../reducers/buildingReducer';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';
import { totalGardenArea, totalParkingArea, totalTerrainArea } from '../../../reducers/terrainReducer';
import { CalculationTableRow } from '../../lib/calctable/CalculationTableRow';
import { CalculationTableRowTerm, Sign } from '../../lib/calctable/CalculationTableRowTerm';
import HorizontalColumnChart from '../../lib/HorizontalColumnChart';
import { hasValidValues, Step } from '../Step';
import { INVESTMENT_STEP } from '../s5-costs/CostsStep';

export const TERRAIN_STEP = 'terrain';

export function TerrainStep() {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	return (
		<Step
			icon='mdi:terrain'
			id={TERRAIN_STEP}
			name='Terrein'
			description='Configureer het terrein omliggend aan het gebouw'
			showContent={state.stepReducer.step === TERRAIN_STEP}
			nextStep={INVESTMENT_STEP}
			thisStep={TERRAIN_STEP}
			nextStepValidations={[
				{ valid: state.terrainReducer.gardens >= 0, error: 'Er moet een aantal tuinen ingevuld zijn, vul 0 in als er geen tuinen zijn' },
				{ valid: state.terrainReducer.parkingSpots >= 0, error: 'Er moet een aantal parkeerplekken ingevuld zijn, vul 0 in als er geen parkeerplekken nodig zijn' },
			]}
			summaryInError={hasValidValues([ totalTerrainArea(state) ])}
			summaryComponent={
				<div>
					<div>
						<span className='bold-value'> {totalTerrainArea(state).toFixed(0)}</span>
						<span> m² bruto</span>
						<span> totaal kaveloppervlak</span>
					</div>
				</div>
			}>
			<CalculationTableRow title='Tuinoppervlakte'>
				<CalculationTableRowTerm
					value={state.terrainReducer.gardens}
					unit='tuinen'
					editable={true}
					mustBeInteger={true}
					decimals={0}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'terrain/setGardens',
								value: floatValue,
							});
						}
					}}
				/>
				<CalculationTableRowTerm
					sign='*'
					value={state.terrainReducer.gardens == 0 ? '-' : state.terrainReducer.gardenArea}
					unit='m²'
					editable={state.terrainReducer.gardens != 0}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'terrain/setGardenArea',
								value: floatValue,
							});
						}
					}}
				/>
				<CalculationTableRowTerm
					sign="="
					value={totalGardenArea(state)}
					unit='m² perceel'
					id='totalGardenArea'
					editable={false}
				/>
			</CalculationTableRow>

			<div className="hor-separator" />

			<CalculationTableRow
				title='Parkeerplaatsen'
				helpText='Een standaard parkeerplek neemt 2,5 * 8 = 20 m² in beslag'>
				<CalculationTableRowTerm
					value={state.terrainReducer.parkingSpots}
					unit='parkeerplaatsen'
					editable={true}
					mustBeInteger={true}
					decimals={0}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'terrain/setParkingSpots',
								value: floatValue,
							});
						}
					}}
				/>
				<CalculationTableRowTerm
					sign='*'
					value={state.terrainReducer.parkingSpots == 0 ? '-' : state.terrainReducer.parkingSpotArea}
					unit='m²'
					editable={state.terrainReducer.parkingSpots != 0}
					onValueChange={({ floatValue }, { event }) => {
						if (event) {
							dispatch({
								type: 'terrain/setParkingSpotArea',
								value: floatValue,
							});
						}
					}}
				/>
				<CalculationTableRowTerm sign="=" value={totalParkingArea(state)} id='totalParkingArea' unit='m² perceel' />
			</CalculationTableRow>

			<div className="hor-separator" />

			<div className='col-2'>
				<div>
					<div style={{ maxHeight: '150px', borderRight: '1px solid #ccc' }}>
						<br />
						<HorizontalColumnChart
							maxWidth={350}
							columns={[
								{ color: '#6366F1', value: buildingFootprint(state), label: 'Gebouw' },
								{ color: '#22C55E', value: totalGardenArea(state), label: 'Tuin' },
								{ color: '#61616A', value: totalParkingArea(state), label: 'Parkeren' },
							]}
						/>
					</div>
				</div>
				<div style={{ paddingLeft: '32px' }}>
					<div>
						<CalculationTableRowTerm
							wide={true}
							value={buildingFootprint(state)}
							unit='m² bvo'
							reference={{ text: 'Footprint', id: 'footprint', step: 'building' }}
						/>
					</div>
					<div>
						<CalculationTableRowTerm
							wide={true}
							value={totalGardenArea(state)}
							unit='m² tuin'
							reference={{ text: 'Tuinoppervlakte', id: 'totalGardenArea', step: 'terrain' }}
						/>
					</div>
					<div>
						<CalculationTableRowTerm
							wide={true}
							value={totalParkingArea(state)}
							unit='m²'
							reference={{ text: 'Parkeerplaatsen', id: 'totalParkingArea', step: 'terrain' }}
						/>
					</div>
					<div className='flex align-center pt-3' style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
						<div style={{ position: 'relative', left: '-16px', top: '2px' }}>
							<Sign sign='+' />
						</div>
						<div style={{ flex: 1 }}>
							<div style={{ borderTop: '2px solid #aaa' }} />
						</div>
					</div>
					<div>
						<CalculationTableRowTerm wide={true} unit='m² kavel nodig' value={totalTerrainArea(state)} />
					</div>
				</div>
			</div>
		</Step>
	);
}
