import { createSelector } from 'reselect';

import { roundNumberToOneDecimal } from '../lib/rounding';
import { buildingAreaTotalBvo, buildingFootprint } from './buildingReducer';
import { totalHousesAreaBvo } from './housingReducer';

export const initialTerrainReducerState = {
	terrainReducer: {
		footprintOverwritten: undefined,
		gardenArea: 100,
		gardens: 1,
		parkingSpotArea: 20,
		parkingSpots: 24,
	},
};

export function terrainReducer(state, action) {
	switch (action.type) {
	case 'terrain/setBuildingFootprint': {
		return {
			...state,
			terrainReducer: {
				...state.terrainReducer,
				footprintOverwritten: action.value,
			},
		};
	}
	case 'terrain/setBuildingFootprintDivisor': {
		return action.value === roundNumberToOneDecimal(buildingFootprintDivisor(state))
			? state
			: {
				...state,
				terrainReducer: {
					...state.terrainReducer,
					footprintOverwritten: buildingAreaTotalBvo(state) / action.value,
				},
				  };
	}
	case 'terrain/resetBuildingFootprint': {
		return {
			...state,
			terrainReducer: {
				...state.terrainReducer,
				footprintOverwritten: undefined,
			},
		};
	}
	case 'terrain/setGardenArea': {
		return {
			...state,
			terrainReducer: {
				...state.terrainReducer,
				gardenArea: action.value,
			},
		};
	}
	case 'terrain/setGardens': {
		return {
			...state,
			terrainReducer: {
				...state.terrainReducer,
				gardens: action.value,
			},
		};
	}
	case 'terrain/setParkingSpotArea': {
		return {
			...state,
			terrainReducer: {
				...state.terrainReducer,
				parkingSpotArea: action.value,
			},
		};
	}
	case 'terrain/setParkingSpots': {
		return {
			...state,
			terrainReducer: {
				...state.terrainReducer,
				parkingSpots: action.value,
			},
		};
	}
	default: {
		return state;
	}
	}
}

const terrain = (state) => state.terrainReducer;

export const totalGardenArea = createSelector(terrain, (terrain) => terrain.gardenArea * terrain.gardens);
export const totalParkingArea = createSelector(terrain, (terrain) => terrain.parkingSpotArea * terrain.parkingSpots);
export const gardenAndParking = createSelector(
	totalGardenArea,
	totalParkingArea,
	(gardenArea, parkingArea) => gardenArea + parkingArea
);

export const totalTerrainArea = createSelector(
	buildingFootprint,
	gardenAndParking,
	(buildingFootprint, gardenAndParking) => buildingFootprint + gardenAndParking
);

export const buildingFootprintDivisor = createSelector(
	totalHousesAreaBvo,
	buildingFootprint,
	(totalHousesAreaBvo, buildingFootprint) => totalHousesAreaBvo / buildingFootprint
);

export const totalBuildingAreaBvo = createSelector(
	totalHousesAreaBvo,
	terrain,
	(totalHousesAreaBvo, terrain) => totalHousesAreaBvo
);

export const buildingFootprintPercentage = createSelector(
	buildingFootprint,
	totalBuildingAreaBvo,
	(buildingFootprint, totalBuildingAreaBvo) => (buildingFootprint / totalBuildingAreaBvo) * 100
);
