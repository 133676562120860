export function SirenIcon({ ...props }) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='24' height='24' viewBox='0 0 172 172' {...props}>
			<g
				fill='none'
				fill-rule='nonzero'
				stroke='none'
				stroke-width='1'
				stroke-linecap='butt'
				stroke-linejoin='miter'
				stroke-miterlimit='10'
				stroke-dasharray=''
				stroke-dashoffset='0'
				font-family='none'
				font-weight='none'
				font-size='none'
				text-anchor='none'>
				<path d='M0,172v-172h172v172z' fill='none'></path>
				<g fill='currentColor'>
					<path d='M146.91667,157.66667h-121.83333c-5.934,0 -10.75,-4.80883 -10.75,-10.75c0,-5.94117 4.816,-10.75 10.75,-10.75h121.83333c5.934,0 10.75,4.80883 10.75,10.75c0,5.94117 -4.816,10.75 -10.75,10.75z'></path>
					<circle cx='20' cy='2' transform='scale(7.16667,7.16667)' r='1'></circle>
					<circle cx='22' cy='7' transform='scale(7.16667,7.16667)' r='1'></circle>
					<circle cx='20' cy='2' transform='scale(7.16667,7.16667)' r='1'></circle>
					<circle cx='22' cy='7' transform='scale(7.16667,7.16667)' r='1'></circle>
					<circle cx='23' cy='13' transform='scale(7.16667,7.16667)' r='1'></circle>
					<circle cx='4' cy='2' transform='scale(7.16667,7.16667)' r='1'></circle>
					<circle cx='2' cy='7' transform='scale(7.16667,7.16667)' r='1'></circle>
					<circle cx='1' cy='13' transform='scale(7.16667,7.16667)' r='1'></circle>
					<path
						d='M150.5,143.33333h-129l18.2105,-97.12983c1.90633,-10.1695 10.78583,-17.53683 21.1345,-17.53683h50.31c10.34867,0 19.22817,7.36733 21.1345,17.53683z'
						opacity='0.35'></path>
				</g>
			</g>
		</svg>
	);
}
