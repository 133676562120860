import { createSelector } from 'reselect';
import { FinancingType, firstYearCapitalRequirement, totalMortgage, totalSoftLoan } from '../financingReducer';
import { energyPerformanceFeeFreeSector, energyPerformanceFeeSocialHousing, income, incomeCommonArea, incomeFreeSectorHousingFirstYear, incomeRentalSocialHousingFirstYear } from '../incomeReducer';
import { buildCostsFreeSectorTotal, buildCostsSocialTotal, totalBuyCosts } from '../investingReducer';
import { serviceCostsFreeSector, serviceCostsSocialHousing, totalServiceCostsVsHuurcommissieRatio } from '../serviceReducer';
import { explotationCostsFreeSector, explotationCostsSocial, totalExplotationCostsMinusSecondYear } from './explotationCosts';
import { saleIncomeFromBuyHomes, totalIncomeSecondYear } from './incomeCalculation';
import { mortgageAnnuity, mortgageCostsSecondYear, mortgageRepaymentSecondYear, softLoanAnnuity, softLoanCostsSecondYear, softLoanRepaymentSecondYear, totalLoanCostsSecondYear } from './loanCosts';


// Dit is AR van kasstroom Woningen
export const totalCashFlowFirstYear = createSelector(
	saleIncomeFromBuyHomes, // included for the verkoop of buy houses
	totalBuyCosts,
	(saleIncomeFromBuyHomes, totalBuyCosts) => {
		return saleIncomeFromBuyHomes - totalBuyCosts;
	}
);
// Dit is prognose financiering AB
// First year should be only the profit that was realised from the sale of the houses
export const firstYearCashFlow = createSelector(
	totalCashFlowFirstYear,
	firstYearCapitalRequirement,
	(totalCashFlowFirstYear, firstYearCapitalRequirement) => {
		return totalCashFlowFirstYear + firstYearCapitalRequirement;
	}
);

// In het 2e jaar:
// - de exploitatiekosten (negatief)
// - het inkomen (positief)
// - de kosten van de leningen (negatief)
export const secondYearCashFlow = createSelector(
	totalExplotationCostsMinusSecondYear,
	totalIncomeSecondYear,
	totalLoanCostsSecondYear,
	(totalExplotationCostsMinusSecondYear, totalIncomeSecondYear, totalLoanCostsSecondYear) => {
		return totalExplotationCostsMinusSecondYear + totalIncomeSecondYear - totalLoanCostsSecondYear;
	}
);

export const years = createSelector(income, (income) => {
	return Array(income.explotationTermPeriodYears + 1)
		.fill(null)
		.map((_, index) => income.explotationFromYear + index);
});

export const cashFlowFollowingYears = (state) => years(state).reduce((previous, year, index) => {
	if (index === 0) {
		return [
			...previous,
			{
				year,
				total: firstYearCashFlow(state),
				totalWithoutFinancing: totalCashFlowFirstYear(state),
				totalIncomeAndExpenses: {
					social: -buildCostsSocialTotal(state),
					freeSector: -buildCostsFreeSectorTotal(state),
				},
			},
		];
	}

	if (index === 1) {
		const huurCommissieServiceCostsRatio = totalServiceCostsVsHuurcommissieRatio(state);
		const groundLeaseCost = (state.investingReducer.groundLeaseAmount ?? 0) * Math.pow(1 + ((state.investingReducer.groundLeaseIndexation ?? 0) / 100), index) * ((state.investingReducer.groundLeaseRate ?? 0) / 100);
		return [
			...previous,
			{
				year,
				total: previous[index - 1].total + secondYearCashFlow(state) - groundLeaseCost,
				totalWithoutFinancing: previous[index - 1].totalWithoutFinancing + totalExplotationCostsMinusSecondYear(state) + totalIncomeSecondYear(state) - groundLeaseCost,
				explotationCosts: {
					freeSector: explotationCostsFreeSector(state),
					social: explotationCostsSocial(state),
				},
				income: {
					rent: {
						social: incomeRentalSocialHousingFirstYear(state),
						freeSector: incomeFreeSectorHousingFirstYear(state),
						commonArea: incomeCommonArea(state),
					},
					epv: {
						social: energyPerformanceFeeSocialHousing(state),
						freeSector: energyPerformanceFeeFreeSector(state),
					},
					serviceCostsAccordingToHuurcommissie: {
						social: serviceCostsSocialHousing(state) * huurCommissieServiceCostsRatio,
						freeSector: serviceCostsFreeSector(state) * huurCommissieServiceCostsRatio,
					},
				},
				totalIncomeAndExpenses: {
					social:
						incomeRentalSocialHousingFirstYear(state) +
						energyPerformanceFeeSocialHousing(state) +
						serviceCostsSocialHousing(state) * huurCommissieServiceCostsRatio -
						explotationCostsSocial(state),
					freeSector:
						incomeFreeSectorHousingFirstYear(state) +
						energyPerformanceFeeFreeSector(state) +
						serviceCostsFreeSector(state) * huurCommissieServiceCostsRatio -
						explotationCostsFreeSector(state),
				},
				loans: {
					mortgage: {
						interestAmount: mortgageCostsSecondYear(state),
						repayment: mortgageRepaymentSecondYear(state), // Maybe not needed
						annuity: mortgageAnnuity(state),
						residualDebt: totalMortgage(state) - mortgageRepaymentSecondYear(state),
					},
					softLoans: {
						interestAmount: softLoanCostsSecondYear(state),
						repayment: softLoanRepaymentSecondYear(state),
						annuity: softLoanAnnuity(state),
						residualDebt: totalSoftLoan(state) - softLoanRepaymentSecondYear(state),
					},
				},
			},
		];
	}

	const income = {
		rent: {
			social:
				previous[index - 1].income.rent.social *
				(1 + state.incomeReducer.rentalIncomeSocialInflationPerYear / 100),
			freeSector:
				previous[index - 1].income.rent.freeSector *
				(1 + state.incomeReducer.rentalIncomeFreeSectorInflationPerYear / 100),
			commonArea:
				previous[index - 1].income.rent.commonArea *
				(1 + state.incomeReducer.rentalIncomeCommonAreaInflationPerYear / 100),
		},
		epv: {
			social:
				previous[index - 1].income.epv.social *
				(1 + state.incomeReducer.energyPerformanceFeePerM2VVoInflationPerYear / 100),
			freeSector:
				previous[index - 1].income.epv.social *
				(1 + state.incomeReducer.energyPerformanceFeePerM2VVoInflationPerYear / 100),
		},
		serviceCostsAccordingToHuurcommissie: {
			social:
				previous[index - 1].income.serviceCostsAccordingToHuurcommissie.social *
				(1 + state.serviceReducer.inflation / 100),
			freeSector:
				previous[index - 1].income.serviceCostsAccordingToHuurcommissie.freeSector *
				(1 + state.serviceReducer.inflation / 100),
		},
	};

	const explotationCosts = {
		freeSector: previous[index - 1].explotationCosts.freeSector * (1 + state.serviceReducer.inflation / 100),
		social: previous[index - 1].explotationCosts.social * (1 + state.serviceReducer.inflation / 100),
	};

	const softLoanInterestAmountIfAnnuity = (state.financingReducer.softLoans.interestRate / 100) * previous[index - 1].loans.softLoans.residualDebt;
	const softLoanIsAnnuity = state.financingReducer.softLoans.type === FinancingType.annuity;
	const softLoanRepayment = softLoanIsAnnuity ? softLoanAnnuity(state) - softLoanInterestAmountIfAnnuity : (totalSoftLoan(state) * (state.financingReducer.softLoans.redemptionPercentage / 100)) / state.financingReducer.softLoans.years;

	// Todo make new function
	const mortgageOver = index + 1 > state.financingReducer.mortgage.years;
	let mortgage;
	if (mortgageOver) {
		mortgage = { interestAmount: 0, repayment: 0, annuity: 0, residualDebt: 0 };
	} else if (state.financingReducer.mortgage.years === index + 1) { // mortgage final year
		mortgage = { interestAmount: 0, repayment: previous[index - 1].loans.mortgage.residualDebt, annuity: 0, residualDebt: 0 };
	} else {
		const mortgageInterestAmount = previous[index - 1].loans.mortgage.residualDebt * (state.financingReducer.mortgage.interestRate / 100);

		const mortgageIsAnnuity = state.financingReducer.mortgage.type === FinancingType.annuity;
		const mortgageRepayment = mortgageIsAnnuity ? previous[index - 1].loans.mortgage.annuity - mortgageInterestAmount : (totalMortgage(state) * state.financingReducer.mortgage.redemptionPercentage) / state.financingReducer.mortgage.years; // TODO: change this to periods if we support multiple periods per mortgage
		mortgage = {
			interestAmount: mortgageInterestAmount,
			repayment: mortgageRepayment,
			annuity: mortgageIsAnnuity ? mortgageAnnuity(state) : undefined,
			residualDebt: previous[index - 1].loans.mortgage.residualDebt - mortgageRepayment,
		};
	}

	// Todo make new function
	const softLoans =
		// softLone is over
		index + 1 > state.financingReducer.softLoans.years
			? {
				interestAmount: 0,
				repayment: 0,
				annuity: 0,
				residualDebt: 0,
			}
			: // Final year for the soft loan
			state.financingReducer.softLoans.years === index + 1
				? {
					interestAmount: 0,
					repayment: previous[index - 1].loans.softLoans.residualDebt,
					annuity: 0,
					residualDebt: 0,
				}
				: // Normal pay for the softloan
				{
					interestAmount:
						state.financingReducer.softLoans.type === FinancingType.annuity
							? softLoanInterestAmountIfAnnuity
							: (state.financingReducer.softLoans.interestRate / 100) * totalSoftLoan(state),
					repayment: softLoanRepayment,

					annuity: softLoanAnnuity(state),
					residualDebt: previous[index - 1].loans.softLoans.residualDebt - softLoanRepayment,
				};

	const loans = {
		mortgage,
		softLoans,
	};

	const totalIncomeAndExpenses = {
		social: income.rent.social + income.epv.social + income.serviceCostsAccordingToHuurcommissie.social - explotationCosts.social,
		freeSector: income.rent.freeSector + income.epv.freeSector + income.serviceCostsAccordingToHuurcommissie.freeSector - explotationCosts.freeSector,
	};

	const loanCostsTotal = loans.softLoans.interestAmount + loans.mortgage.interestAmount + loans.softLoans.repayment + loans.mortgage.repayment;
	const groundLeaseCost = (state.investingReducer.groundLeaseAmount ?? 0) * Math.pow(1 + ((state.investingReducer.groundLeaseIndexation ?? 0) / 100), index) * ((state.investingReducer.groundLeaseRate ?? 0) / 100);

	const totalIncome = totalIncomeAndExpenses.social + totalIncomeAndExpenses.freeSector;
	const totalWithoutFinancing = previous[index - 1].totalWithoutFinancing + totalIncome - groundLeaseCost;
	const total = previous[index - 1].total + totalIncome - loanCostsTotal - groundLeaseCost;

	return [ ...previous, { year, total, totalWithoutFinancing, explotationCosts, income, loans, totalIncomeAndExpenses } ];
}, []);
