import { createSelector } from 'reselect';

import { bvoToVvoMultiplier } from './StepsStoreProvider';

export function housingReducer(state, action) {
	switch (action.type) {
	case 'housing/setMunicipality': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				municipality: action.municipality,
			},
		};
	}
	case 'housing/noHouses': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				noHouses: {
					...state.housingReducer.noHouses,
					[action.name]: action.value,
				},
			},
		};
	}
	case 'housing/areaBvo': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				areaBvo: {
					...state.housingReducer.areaBvo,
					[action.name]: action.value,
				},
				areaVvo: {
					...state.housingReducer.areaVvo,
					[action.name]: action.value * bvoToVvoMultiplier,
				},
			},
		};
	}
	case 'housing/areaVvo': {
		return {
			...state,
			housingReducer: {
				...state.housingReducer,
				areaVvo: {
					...state.housingReducer.areaVvo,
					[action.name]: action.value,
				},
			},
		};
	}
	default: {
		return state;
	}
	}
}

export const initialHousingReducerState = {
	housingReducer: {
		municipality: null,
		noHouses: {
			social: 0,
			freeSector: 0,
			buy: 0,
		},
		areaBvo: {
			social: 60,
			freeSector: 80,
			buy: 100,
		},

		areaVvo: {
			social: 48,
			freeSector: 64,
			buy: 80,
		},
	},
};

export const housing = (state) => state.housingReducer;

export const noHouses = (state) => state.housingReducer.noHouses;
export const totalNoOfHouses = createSelector(noHouses, (noHouses) => Object.values(noHouses).reduce((tot, moreHouses) => tot + moreHouses, 0)
);

const areaBvo = (state) => state.housingReducer.areaBvo;
export const totalHousesAreaBvo = createSelector(noHouses, areaBvo, (noHouses, areaBvo) => Object.keys(areaBvo).reduce((tot, key) => tot + noHouses[key] * areaBvo[key], 0)
);

export const areaVvo = (state) => state.housingReducer.areaVvo;
export const totalSocialHousesAreaVvo = createSelector(
	noHouses,
	areaVvo,
	(noHouses, areaVvo) => noHouses.social * areaVvo.social
);

export const totalHousesAreaVvo = createSelector(noHouses, areaVvo, (noHouses, areaVvo) => Object.keys(areaVvo).reduce((tot, key) => tot + noHouses[key] * areaVvo[key], 0)
);

export const vormRatio = createSelector(
	totalHousesAreaBvo,
	totalHousesAreaVvo,
	(totalHousesAreaBvo, totalHousesAreaVvo) => totalHousesAreaVvo / totalHousesAreaBvo
);
