import { useRef } from 'react';
import html2canvas from 'html2canvas';
import { Icon } from '@iconify/react';

export default function ExportableAsPng({ fileName, btnAbove = false, children }) {
	const exportRef = useRef(null);

	return <div style={{ position: 'relative' }}>
		<button className="invisible-btn" title="Opslaan als plaatje" onClick={() => {
			html2canvas(exportRef.current).then((canvas) => {
				const link = document.createElement('a');
				link.download = fileName + '.png';
				link.href = canvas.toDataURL("image/png");
				link.click();
			});
		}} style={{ position: 'absolute', top: btnAbove ? '-56px' : 0, right: 0, background: 'transparent', border: 0, fontSize: '1.5em' }}>
			<Icon icon="ant-design:picture-filled" />
		</button>

		<div ref={exportRef} className="png-exportable">
			{children}
		</div>
	</div>;
}